import React, { useState } from 'react';
import CenteredSection from '../components/base/CenteredSection';
import FormItem from '../components/FormItem';
import Input from '../components/base/Input';
import Button from '../components/base/Button';
import api from '../api/api';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import store from '../store/roomStore';

const StyledContent = styled.form`
  width: 20%;
  text-align: center;
`;

export default function Auth() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const logIn = async () => {
    try {
      const {data: {token}} = await api.post('auth/token', {username, password});

      localStorage.setItem('token', token);
      localStorage.setItem('login', username);
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
      store.isAdmin = true;
      setRedirect(true);
    } catch {
      alert('Неверный логин или пароль');
    }
  }

  return redirect ? <Redirect to="/" /> : (
    <CenteredSection>
      <StyledContent onSubmit={e => {e.preventDefault(); logIn()}}>
        <h1>Авторизация</h1>
        <FormItem label="Логин&nbsp;" labelWidth="100px">
          <Input placeholder="username" value={username} onChange={e => setUsername(e.currentTarget.value)} type="text"/>
        </FormItem>
        <FormItem label="Пароль&nbsp;" labelWidth="100px">
          <Input placeholder="•••••••••" value={password} onChange={e => setPassword(e.currentTarget.value)} type="password"/>
        </FormItem>
        <Button onClick={logIn}>Войти</Button>
      </StyledContent>
    </CenteredSection>
  )
}
