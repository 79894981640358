import React from 'react';
import deckImg from '../assets/deck.svg';
import styled from 'styled-components';
import store from '../store/roomStore';
import { observer } from 'mobx-react';

const StyledContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 30px;
  width: max-content;
  align-items: center;
`;

const StyledText = styled.div`
  position: absolute;
  font-size: 36px;
  font-weight: bold;
  color: white;
  padding-left: 1.25rem;
  justify-content: center;
  pointer-events: none;
`;

const Deck = observer(() => {
  return (
    <StyledContainer>
      <StyledText>{store.deckSize}</StyledText>
      <img src={deckImg} alt="Колода"/>
    </StyledContainer>
  )
});

export default Deck;