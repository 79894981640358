import axios from 'axios';

const {REACT_APP_API_URL} = process.env;

console.log('API URL (form env): ', REACT_APP_API_URL)

const instance = axios.create({
  // dev
  baseURL: REACT_APP_API_URL ?? '/api/'
  // prod
  // baseURL: "http://dandelion-game.ru/"
});

if (localStorage.getItem('token')) {
  instance.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

instance.interceptors.response.use(res => res, err => {
  if (err.response?.status === 401 || err.response?.status === 403) {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
  }
})

export default instance;