import React from 'react';
import CenteredSection from '../base/CenteredSection';
import styled from 'styled-components';

interface Props {
	onFinish(): any
}

const StyledButton = styled.button`
  cursor: pointer;
  color: #85C03C;
  border: 1px solid #85C03C;
  font-size: 14px;
  background: #fff;
  box-shadow: 0px 4px 5px rgba(16, 156, 241, 0.1);
  border-radius: 4px;
  padding: 13px 30px;
  margin-top: 48px;
`;

const StyledFeeling = styled.div`
  padding: 35px 50px;
  margin-top: 15px;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  background-color: #ffcd00;
  border-radius: 5px;
`;

export default function ModalOpenFeeling(props: Props) {
  const [isOpened, setOpenedState] = React.useState(false);
  const feeling = localStorage.getItem('last-emotion');

  const setOpened = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const section = e.currentTarget.parentElement;

    section!.style.transition = 'transform 0.3s ease-in-out';
    section!.style.transform = 'scaleX(0)';

    setTimeout(() => {
      setOpenedState(true);
      // we can do this because the section is reused
      section!.style.transform = '';
    }, 300);
  }

  return !isOpened ? (
    <CenteredSection>
      <h2 className="text-center">Поздравляем! У вас новый одуванчик! <br/><br/> Вспомните и расскажите нам о своём самом счастливом моменте в жизни!</h2>
      <StyledButton onClick={setOpened}>Открыть</StyledButton>
    </CenteredSection>
  ) : (
    <CenteredSection>
      <StyledFeeling>{feeling}</StyledFeeling>
      <StyledButton onClick={props.onFinish}>Заполнить таблицу "Мои одуванчики"</StyledButton>
    </CenteredSection>
  )
}
