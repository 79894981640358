import {fabric} from 'fabric';
import { Vector2, dist } from '../entities/game/Vector2';
import { Tile } from './gameObjects/tile';
import { TileType } from './gameObjects/tile';
import { DraggableTile } from './gameObjects/draggableTile';
import { Chip } from './gameObjects/chip';
import store, { PlayerTurnPhases } from '../store/roomStore';
import { autorun, IReactionDisposer } from 'mobx';
import wsApi from '../api/wsApi';
import { EventFieldCollectFlower } from '../api/wsEvents';

export class GameField {
  tiles: (Tile | undefined)[] = [];
  canvas: fabric.Canvas;
  tileSide = 36;
  availableTile: DraggableTile | undefined = undefined;
  chips: Chip[] = [];
  topPuzzleAutorun: IReactionDisposer;
  chipPlacingAutorun: IReactionDisposer;

  constructor(canvas: fabric.Canvas) {
    this.canvas = canvas;

    // Top puzzle generation
    this.topPuzzleAutorun = autorun(() => {
      if (!store.topPuzzle) return;

      const flowers = store.topPuzzle.vertices_state.reduce((arr, flower, index) => {
        if (flower) arr.push(index);
        return arr;
      }, [] as number[]);
      this.setAvailableTile(flowers, store.topPuzzle.id);

    });

    // filling tiles from store data
    this.clear();
    store.field.forEach(puzzle => {
      const flowers = puzzle.vertices_state.reduce((acc, vertice, index) => {
        if (vertice) acc.push(index);
        return acc;
      }, [] as number[]);
      this.addTile(puzzle.x, puzzle.y, TileType.Placed, flowers);
    });

    // Placing chips in sync with store
    this.chipPlacingAutorun = autorun(() => {
      const equalSets = Object.keys(store.placedChips).reduce((equal, key) => {
        const placedChip = store.placedChips[key];
        return equal && this.chips.findIndex(chip => {
          return chip.ownerKey === key && chip.x === placedChip.x && chip.y === placedChip.y && chip.color === placedChip.color
        }) !== -1;
      }, true) && Object.keys(store.placedChips).length === Object.keys(this.chips).length;

      if (equalSets) return;
      
      this.chips.forEach(chip => {
        chip.destroyObject();
      });
      this.chips = [];

      Object.entries(store.placedChips).forEach(([sessionKey, chip]) => {
        this.addChip(chip.x!, chip.y!, chip.color!, sessionKey);
      });
    });
  }

  checkFlowersUnderChips() {
    const chips = this.chips.filter((chip) => {
      // The vertice always corresponds to first item in array
      const mainTile = chip.intersectingTiles[0];
      const surrounded = chip.intersectingTiles.reduce((acc, tile) => acc && this.getTileByLocal(tile.x, tile.y)?.type === TileType.Placed, true);

      console.log(chip, surrounded, this.getTileByLocal(mainTile.x, mainTile.y), this.getTileByLocal(mainTile.x, mainTile.y)?.flowersTaken)

      return surrounded && this.getTileByLocal(mainTile.x, mainTile.y)?.flowersTaken[chip.vertice];
    });

    const flowerBlacklist: Vector2[] = [];
    chips.forEach((chip) => {
      flowerBlacklist.push({ x: chip.x, y: chip.y });
      // Rewriting sender_key for admin-controlled tile placement
      wsApi.sendEvent(EventFieldCollectFlower.type, {chip_owner_key: chip.ownerKey, initiator_key: store.actingPlayerSessionKey}, true);
    });

    localStorage.setItem('flower-blacklist', JSON.stringify(flowerBlacklist));

    return chips;
  }

  addChip(x: number, y: number, color: string, ownerKey: string) {
    this.chips.push(this.generateChip(x, y, color, ownerKey));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, intersectingTiles, vertice] = this.getClosestCorner(x, y);
    this.chips[this.chips.length - 1].intersectingTiles = intersectingTiles;
    this.chips[this.chips.length - 1].vertice = vertice;
    this.chips[this.chips.length - 1].bringToFront();
  }

  isCornerAvailable(firstTileVertice: number, intersectingTiles: Vector2[]) {
    // Intersecting tiles are guaranteed to be in clockwise order, so we rely on that
    const anyTileIndex = intersectingTiles.findIndex(tile => this.getTileByLocal(tile.x, tile.y)?.type === TileType.Placed);
    const isSurrounded = !!this.getTileByLocal(intersectingTiles[0].x, intersectingTiles[0].y)?.flowers[firstTileVertice]
      && !!this.getTileByLocal(intersectingTiles[1].x, intersectingTiles[1].y)?.flowers[(firstTileVertice + 2) % 6]
      && !!this.getTileByLocal(intersectingTiles[2].x, intersectingTiles[2].y)?.flowers[(firstTileVertice + 4) % 6];

    if (isSurrounded) {
      const lastPlacedTile = JSON.parse(localStorage.getItem('last-placed-tile') || '{}');
      const placingOnSelfCollectedFlower = (store.playerTurnPhase === PlayerTurnPhases.PlacedPuzzle 
        && intersectingTiles.findIndex(tile => tile.x === lastPlacedTile.x && tile.y === lastPlacedTile.y) !== -1);
      
      if (!placingOnSelfCollectedFlower)
        return false;
    }

    if (anyTileIndex === -1) return false;

    switch(anyTileIndex) {
      case 1:
        firstTileVertice = (firstTileVertice + 2) % 6; 
        break;
      case 2:
        firstTileVertice = (firstTileVertice + 4) % 6; 
    }

    return !!this.getTileByLocal(intersectingTiles[anyTileIndex].x, intersectingTiles[anyTileIndex].y)!.flowers[firstTileVertice];
  }

  bringChipsToFront() {
    this.chips.forEach(chip => chip.bringToFront());
  }

  generateChip(x: number, y: number, color: string, ownerKey: string) {
    return new Chip(this.canvas, x, y, color, ownerKey, this.tileSide * 1.5);
  }

  setAvailableTile(flowers: number[], id: number) {
    this.availableTile?.destroy();

    store.isTopPuzzleFlipped = true;
    this.availableTile = new DraggableTile(
      this.canvas,
      this.tileSide,
      id,
      flowers
    );
    setTimeout(() => this.resetAvailableTilePosition());
  }

  resetAvailableTilePosition() {
    if (!this.availableTile) return;
    
    this.availableTile!.x = this.canvas.getWidth() - this.tileSide * 2 - this.canvas.viewportTransform![4];
    this.availableTile!.y = 300 - this.canvas.viewportTransform![5];

    console.log(this.availableTile.x, this.availableTile.y, this.canvas.viewportTransform)

    this.availableTile.bringToFront();
    this.canvas.requestRenderAll();
  }

  getTileByGlobal(x: number, y: number): Tile | undefined {
    const local = this.globalToLocal({x, y});

    return this.tiles[local.x + local.y * 1000];
  }

  getTileByLocal(x: number, y: number) {
    return this.tiles[x + y * 1000];
  }

  getClosestCorner(x: number, y: number): [Vector2, Vector2[], number] {
    const closestTileLocal = this.globalToLocal({x, y});
    const closestTile = this.localToGlobal(closestTileLocal);
    const intersectingTiles = [closestTileLocal];

    const vertices = [
      {x: closestTile.x, y: closestTile.y - this.tileSide},
      {x: closestTile.x + this.tileSide * Math.sqrt(3) / 2, y: closestTile.y - this.tileSide / 2},
      {x: closestTile.x + this.tileSide * Math.sqrt(3) / 2, y: closestTile.y + this.tileSide / 2},
      {x: closestTile.x, y: closestTile.y + this.tileSide},
      {x: closestTile.x - this.tileSide * Math.sqrt(3) / 2, y: closestTile.y + this.tileSide / 2},
      {x: closestTile.x - this.tileSide * Math.sqrt(3) / 2, y: closestTile.y - this.tileSide / 2}
    ];

    const closest = vertices.reduce((acc, current) => {
      if (dist({x, y}, current) < dist({x, y}, acc)) return current;
      return acc;
    }, vertices[0]);

    const verticeIndex = vertices.findIndex(vertice => vertice === closest);

    switch(verticeIndex) {
      case -1:
        console.error('wtf');
        break;
      case 0:
        intersectingTiles.push(
          {x: closestTileLocal.x, y: closestTileLocal.y - 1}, 
          {x: closestTileLocal.x + 1, y: closestTileLocal.y - 1},
        );
        break;
      case 1:
        intersectingTiles.push(
          {x: closestTileLocal.x + 1, y: closestTileLocal.y - 1},
          {x: closestTileLocal.x + 1, y: closestTileLocal.y},
        );
        break;
      case 2:
        intersectingTiles.push(
          {x: closestTileLocal.x + 1, y: closestTileLocal.y},
          {x: closestTileLocal.x, y: closestTileLocal.y + 1}, 
        );
        break;
      case 3:
        intersectingTiles.push(
          {x: closestTileLocal.x, y: closestTileLocal.y + 1}, 
          {x: closestTileLocal.x - 1, y: closestTileLocal.y + 1},
        );
        break;
      case 4:
        intersectingTiles.push(
          {x: closestTileLocal.x - 1, y: closestTileLocal.y + 1},
          {x: closestTileLocal.x - 1, y: closestTileLocal.y}, 
        );
        break;
      case 5:
        intersectingTiles.push( 
          {x: closestTileLocal.x - 1, y: closestTileLocal.y},
          {x: closestTileLocal.x, y: closestTileLocal.y - 1},
        );
        break;
    }

    return [closest, intersectingTiles, verticeIndex];
  }

  containsPlacedTile(x: number, y: number) {
    return this.getTileByLocal(x, y)?.type === TileType.Placed;
  }

  addTile(x: number, y: number, type: TileType = TileType.Placed, flowers?: number[]) {
    const global = this.localToGlobal({x, y});

    const currentTile = this.getTileByLocal(x, y);

    if (type === TileType.Vacant) {
      if (currentTile !== undefined) return;

      this.tiles[x + y * 1000] = new Tile(this.canvas, TileType.Vacant, global.x, global.y, this.tileSide);
      return;
    }

    currentTile?.destroy();
    this.tiles[x + y * 1000] = new Tile(this.canvas, TileType.Placed, global.x, global.y, this.tileSide, flowers);

    for (let i = -1; i <= 1; ++i) {
      for (let j = -1; j <= 1; ++j) {
        if (i === j) continue;

        this.addTile(x + i, y + j, TileType.Vacant);
      }
    }
    this.bringChipsToFront();
    this.availableTile?.bringToFront();
  }

  canAddTile(x: number, y: number, flowers?: number[]) {
    if (!flowers) return;
    let result = true;

    [0, 1, 2, 3, 4, 5].forEach(index => {
      switch(index) {
        case 0:
          result = result 
            // if the flower is present on index, neighboring tiles should also have flowers there. Otherwise, they should not.
            && (!this.containsPlacedTile(x, y - 1) || (flowers.includes(index) === !!this.getTileByLocal(x, y - 1)!.flowers[2]))
            && (!this.containsPlacedTile(x + 1, y - 1) || (flowers.includes(index) === !!this.getTileByLocal(x + 1, y - 1)!.flowers[4]));
          break;
        case 1:
          result = result 
            && (!this.containsPlacedTile(x + 1, y - 1) || (flowers.includes(index) === !!this.getTileByLocal(x + 1, y - 1)!.flowers[3]))
            && (!this.containsPlacedTile(x + 1, y) || (flowers.includes(index) === !!this.getTileByLocal(x + 1, y)!.flowers[5]));
          break;
        case 2:
          result = result 
            && (!this.containsPlacedTile(x + 1, y) || (flowers.includes(index) === !!this.getTileByLocal(x + 1, y)!.flowers[4]))
            && (!this.containsPlacedTile(x, y + 1) || (flowers.includes(index) === !!this.getTileByLocal(x, y + 1)!.flowers[0]));
          break;
        case 3:
          result = result 
            && (!this.containsPlacedTile(x, y + 1) || (flowers.includes(index) === !!this.getTileByLocal(x, y + 1)!.flowers[5]))
            && (!this.containsPlacedTile(x - 1, y + 1) || (flowers.includes(index) === !!this.getTileByLocal(x - 1, y + 1)!.flowers[1]));
          break;
        case 4:
          result = result 
            && (!this.containsPlacedTile(x - 1, y + 1) || (flowers.includes(index) === !!this.getTileByLocal(x - 1, y + 1)!.flowers[0]))
            && (!this.containsPlacedTile(x - 1, y) || (flowers.includes(index) === !!this.getTileByLocal(x - 1, y)!.flowers[2]));
          break;
        case 5:
          result = result 
            && (!this.containsPlacedTile(x - 1, y) || (flowers.includes(index) === !!this.getTileByLocal(x - 1, y)!.flowers[1]))
            && (!this.containsPlacedTile(x, y - 1) || (flowers.includes(index) === !!this.getTileByLocal(x, y - 1)!.flowers[3]));
          break;
      }
    });
    return result;
  }

  shiftUI(x: number, y: number) {
    if (this.availableTile) {

      this.availableTile!.x! -= x;
      this.availableTile!.y! -= y;
    }
  }

  globalToLocal({x, y}: Vector2) {
    const localX = (x * Math.sqrt(3) / 3 - y / 3) / this.tileSide;
    const localY = (y * 2 / 3) / this.tileSide; 

    return {
      x: Math.round(localX),
      y: Math.round(localY)
    };
  }

  localToGlobal({x, y}: Vector2) {
    return {
      x: x * (this.tileSide * Math.sqrt(3)) + y * (this.tileSide * Math.sqrt(3) / 2),
      y: y * (this.tileSide * 1.5)
    };
  }

  clear() {
    this.tiles.forEach(tile => {
      tile?.destroy();
    });
  }

  dispose() {
    this.topPuzzleAutorun && this.topPuzzleAutorun();
    this.chipPlacingAutorun && this.chipPlacingAutorun();
  }
}