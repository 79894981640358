import {fabric} from 'fabric';
import { Tile, TileType } from './tile';
import translateIcon from '../../assets/icon-translate.svg';
import rotateIcon from '../../assets/icon-rotate.svg';
import { GameObject } from './gameObject';
import wsApi from '../../api/wsApi';
import { EventPuzzleStackRotate, EventPuzzleStackMoveNext } from '../../api/wsEvents';
import { autorun, IReactionDisposer } from 'mobx';
import store, { PlayerTurnPhases } from '../../store/roomStore';
import { Vector2 } from '../../entities/game/Vector2';

export class DraggableTile extends Tile {
  id: number = -1; // Only used to pass to websockets 
  moveControl!: GameObject;
  rotateControl!: GameObject;
  isDragged = false;
  rotation = 0;
  side: number;
  animationConfig: fabric.IAnimationOptions;

  controlsAutorun!: IReactionDisposer;

  constructor(canvas: fabric.Canvas, side = 20, id: number, flowers?: number[]) {
    super(canvas, TileType.Available, 0, 0, side, []);
    this.animationConfig = {
      onChange: () => this.canvas.requestRenderAll(),
      duration: 200,
      easing: fabric.util.ease.easeInOutExpo
    };
    this.id = id;

    [0, 1, 2, 3, 4, 5].forEach(index => {
      this.setFlower(index, 0, 0, side, !flowers?.includes(index), true, true);
    });

    this.side = side;

    if (store.isAdmin || store.playerTurnPhase === PlayerTurnPhases.PlacingPuzzle) {
      this.initControls(side);
    }
  }

  initControls(side: number) {
    fabric.Image.fromURL(translateIcon, img => {
      if (!this.objectGroup) return;
      this.moveControl = new GameObject(img, this.canvas);
      this.moveControl.object.set({ left: this.x - side * Math.sqrt(3) / 4, top: this.y, selectable: true });
      this.moveControl.object.setCoords();

      this.moveControl.object.on('moving', e => {
        this.isDragged = true;
        
        this.x = this.moveControl.x + side * Math.sqrt(3) / 4;
        this.y = this.moveControl.y;
      });

      this.moveControl.object.on('mouseup', e => {
        this.isDragged = false;
      });
    });

    fabric.Image.fromURL(rotateIcon, img => {
      if (!this.objectGroup) return;
      this.rotateControl = new GameObject(img, this.canvas);
      this.rotateControl.object.set({ left: this.x + side * Math.sqrt(3) / 4, top: this.y });
      this.rotateControl.object.setCoords();

      this.rotateControl.object.on('mousedown', e => {
        this.rotate();
        wsApi.sendEvent(EventPuzzleStackRotate.type, { newRotation: this.rotation });
      });
    });
  }

  bringToFront() {
    this.canvas?.bringToFront(this.objectGroup);
    this.moveControl && this.canvas.bringToFront(this.moveControl.object);
    this.rotateControl && this.canvas.bringToFront(this.rotateControl.object);
  }

  syncPosition(baseCanvasOffset: Vector2) {
    wsApi.sendEvent(EventPuzzleStackMoveNext.type, { newPosition: { x: this.x + this.canvas.viewportTransform![4] - baseCanvasOffset.x, y: this.y + this.canvas.viewportTransform![5] - baseCanvasOffset.y } });
  }

  getFlowersArray() {
    return this.flowers
      .map((flower, index) => index)
      .filter(index => this.flowers[index]?.object.opacity !== 0)
      .map(index => (index + this.rotation) % 6);
  }

  rotate(rotation?: number) {
    if (rotation) {
      this.rotation = rotation;
    } else {
      ++this.rotation;
    }
    this.leaves.animate('angle', -60 * this.rotation, this.animationConfig);
    this.objectGroup.animate('angle', 60 * this.rotation, {
      ...this.animationConfig,
      onComplete: () => {
        if (this.rotation === 6) {
          this.rotation = 0;
          this.objectGroup.angle = 0;
        }
      }
    });
  }

  translateTo(x: number, y: number) {
    this.objectGroup.animate('left', x, this.animationConfig);
    this.moveControl.object.animate('left', x - this.side * Math.sqrt(3) / 4, this.animationConfig);
    this.rotateControl.object.animate('left', x + this.side * Math.sqrt(3) / 4, this.animationConfig);
    this.objectGroup.animate('top', y, this.animationConfig);
    this.moveControl.object.animate('top', y, this.animationConfig);
    this.rotateControl.object.animate('top', y, this.animationConfig);
  }

  get x() {
    return this.objectGroup.left!;
  }

  set x(value) {
    this.objectGroup.set({ left: value });
    this.moveControl?.object.set({ left: value - this.side * Math.sqrt(3) / 4 });
    this.rotateControl?.object.set({ left: value + this.side * Math.sqrt(3) / 4 });
    this.objectGroup.setCoords();
    this.moveControl?.object.setCoords();
    this.rotateControl?.object.setCoords();
    this.canvas.requestRenderAll();
  }
  
  get y() {
    return this.objectGroup.top!;
  }

  set y(value) {
    this.objectGroup.set({ top: value });
    this.moveControl?.object.set({ top: value })
    this.rotateControl?.object.set({ top: value });
    this.objectGroup.setCoords();
    this.moveControl?.object.setCoords();
    this.rotateControl?.object.setCoords();
    this.canvas.requestRenderAll();
  }
  
  destroy() {
    this.moveControl?.destroy();
		this.rotateControl?.destroy();
    super.destroy();
    store.isTopPuzzleFlipped = false;
    store.topPuzzle = null;
    this.controlsAutorun?.();
  }
}