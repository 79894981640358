import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import wine from '../assets/icon-wine.svg';
import flower from '../assets/icon-flower.svg';
import smile from '../assets/icon-smile.svg';
import leading from '../assets/icon-lead.svg';
import current from '../assets/icon-current.svg';
import avatar from '../assets/avatar-base.svg';
import iconMore from '../assets/icon-more.svg';
import iconProfile from '../assets/icon-profile.svg';
import iconButterfly from '../assets/icon-butterfly.svg';
import iconButterfly2 from '../assets/icon-butterfly-2.svg';
import iconKick from '../assets/icon-kick.svg';
import iconFeeling from '../assets/icon-feeling.svg';
import iconWineCard from '../assets/icon-wine-card.svg';
import MiniCard from './base/MiniCard';
import store, { Player, RoomStatus, ViewPhase } from '../store/roomStore';
import wsApi from '../api/wsApi';
import { EventPlayerUnlockChip, EventPlayerGiveEmotionCard, EventPlayerGiveQuoteCard, EventPlayerKicked, EventGameStartRound } from '../api/wsEvents';
import { observer } from 'mobx-react';

interface Props {
  player: Player;
  displayInfo?(): void;
}


const Item = styled.div`
  display: grid;
  max-height: 3rem;
  grid-template:  'icon-lead avatar name name name' 1fr
                  'icon-current avatar wine flowers current' 1fr 
                  / max-content 1fr 1fr 1fr min-content;

  > .iconed-counter {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 0.5rem;

    > img {
      margin-right: 0.5rem;
    }
  }

  > .player-name {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    grid-area: name;
    outline: none;

    &::-moz-focus-inner {
      border: 0;
    }

    > .popup-icon {
      cursor: pointer;
      margin-left: 10px;
      padding: 0 5px;
      filter: grayscale(100%);
    }

    > .player-popup {
      position: absolute;
      left: calc(1rem + 100%);
      top: 0;

      &::before {
        content: '';
        position: absolute;
        left: -0.55rem;
        transform: rotate(45deg);
        background-color: #fff;
        border: 1px solid #A0D95A;
        border-right: none;
        border-top: none;
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;

const Avatar = styled.object`
  grid-area: avatar;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 8px;
`;

const StyledPopupItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  white-space: nowrap;

  &:hover {
    background-color: #eee;
  }

  &[data-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default observer((props: Props) => {
  const [popupActive, setPopupActive] = useState(false);
  const avatarRef = React.createRef<HTMLObjectElement>();
  const recolor = () => {
    avatarRef.current?.contentDocument?.getElementsByTagName('path')[0]?.setAttribute('fill', props.player.chip?.color || 'gray');
  }

  useEffect(recolor);

  return (
    <Item>
      <img src={leading} alt="Побеждает" style={{gridArea: 'icon-lead', visibility: store.leadingPlayerSessionKey === props.player.session_key ? 'visible' : 'hidden'}} />
      <img src={current} alt="Ходит" style={{gridArea: 'icon-current', visibility: store.actingPlayerSessionKey === props.player.session_key ? 'visible' : 'hidden'}} />
      <Avatar onLoad={recolor} ref={avatarRef} data={avatar} />
      <div tabIndex={0} className="player-name" onBlurCapture={() => setPopupActive(false)}>
        <span>{props.player.name || 'Анонимный игрок'}</span>
        { store.isAdmin && store.currentPlayer?.session_key !== props.player.session_key && 
          <>
            <img className="popup-icon" src={iconMore} alt="Подробнее" onClick={() => setPopupActive(true)} style={popupActive ? {filter: 'none'} : undefined} />
            {popupActive && 
              <MiniCard className="player-popup" style={{width: 'max-content'}} onClick={() => setPopupActive(false)}>
                <StyledPopupItem data-disabled={false} onClick={props.displayInfo}>
                  <img className="mr-3 icon" src={iconProfile} alt="Анкета игрока"/>
                  <span>Анкета игрока</span>
                </StyledPopupItem>
								<StyledPopupItem data-disabled={store.roomStatus !== RoomStatus.Started} onClick={() => wsApi.sendEvent(EventGameStartRound.type, { player_key: props.player.session_key }, true)}>
									<img className="mr-3 icon" src={smile} alt="Передать ход"/>
									<span>Передать ход</span>
								</StyledPopupItem>
								<StyledPopupItem 
									data-disabled={store.roomStatus !== RoomStatus.Started || Object.keys(store.placedChips).includes(props.player.session_key)} 
									onClick={() => {
										store.heldForeignChip = store.heldForeignChip === null
											? { ownerKey: props.player.session_key, color: props.player.chip.color }
											: null;
										store.isDraggingChip = store.heldForeignChip !== null;
									}}
								>
                  <img className="mr-3 icon" src={iconButterfly2} alt="Переместить бабочку"/>
                  <span>{store.heldForeignChip === null ? 'Переместить бабочку' : 'Отпустить бабочку'}</span>
                </StyledPopupItem>
                <StyledPopupItem data-disabled={store.roomStatus !== RoomStatus.Started || !Object.keys(store.placedChips).includes(props.player.session_key)} onClick={() => wsApi.sendEvent(EventPlayerUnlockChip.type, {player_key: props.player.session_key}, true)}>
                  <img className="mr-3 icon" src={iconButterfly} alt="Разрешить перелёт"/>
                  <span>Разрешить перелёт</span>
                </StyledPopupItem>
                <StyledPopupItem data-disabled={false} onClick={() => wsApi.sendEvent(EventPlayerKicked.type, { player_key: props.player.session_key }, true)}>
                  <img className="mr-3 icon" src={iconKick} alt="Исключить игрока"/>
                  <span>Исключить игрока</span>
                </StyledPopupItem>
                <StyledPopupItem data-disabled={false} onClick={() => wsApi.sendEvent(EventPlayerGiveEmotionCard.type, { player_key: props.player.session_key })}>
                  <img className="mr-3 icon" src={iconFeeling} alt="Выдать карточку"/>
                  <span>Выдать карточку чувства</span>
                </StyledPopupItem>
                <StyledPopupItem data-disabled={false} onClick={() => wsApi.sendEvent(EventPlayerGiveQuoteCard.type, { player_key: props.player.session_key })}>
                  <img className="mr-3 icon" src={iconWineCard} alt="Выдать карточку"/>
                  <span>Выдать карточку вина</span>
                </StyledPopupItem>
              </MiniCard> 
            }
          </>
         } 
      </div> 
      <div className="iconed-counter" style={{gridArea: 'wine'}}>
        <img src={wine} alt="Бокалы вина" />{props.player.wine_count}
      </div>
      <div className="iconed-counter" style={{gridArea: 'flowers'}}>
        <img src={flower} alt="Одуванчики" />{props.player.dandelion_count}
      </div>
      { store.currentPlayer?.session_key === props.player.session_key && (
        <div style={{gridArea: 'current', width: '1rem', display: 'flex', alignItems: 'center'}}>
          <img src={smile} alt="Мои одуванчики" title="Мои одуванчики" style={{cursor: 'pointer'}} onClick={() => store.viewPhase = ViewPhase.ViewingDandelionTable} />
        </div>
      ) }
    </Item>
  )
});