import React from 'react';
import CenteredSection from '../base/CenteredSection';
import styled from 'styled-components';
import bgButterfly1 from '../../assets/bg-butterfly-1.svg';
import bgButterfly2 from '../../assets/bg-butterfly-2.svg';
import bgDandelion from '../../assets/bg-dandelion.svg';
import api from '../../api/api';
import store from '../../store/roomStore';
import { useParams } from 'react-router-dom';
import Button from '../base/Button';
import wsApi from '../../api/wsApi';
import { EventPlayerChangeJuiceText } from '../../api/wsEvents';
import game from '../../game/main';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

interface Props {
  onFinish(): void
}

interface RouteParams {
  roomKey: string
}

const StyledHeader = styled.header`
  width: 100%;
  text-align: center;
  background: url(${bgButterfly1}) bottom left no-repeat, url(${bgButterfly2}) top right no-repeat;
  padding: 50px 0 36px;
`;

const StyledTextArea = styled.textarea`
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  resize: none;
  width: 100%;
  height: 30vh;
  padding: 1rem;
  margin-bottom: 10vh;
`;

export default observer((props: Props) => {
  const { roomKey } = useParams<RouteParams>();
  const [juice, setJuice] = React.useState('');

  const loadGameData = () => {
    const loaded = new Promise((resolve, reject) => {
      if (!store.loaded && !store.loading) {
        store.fetchRoom(roomKey).then(() => {
          game.useWebSocket(api.defaults.baseURL!.replace('http', 'ws') + `game-stream/${roomKey}`).then(() => resolve());
        });
      } else if (store.loading) {
        alert('Что-то пошло не так при загрузке данных. Попробуйте перезагрузить страницу');
        reject();
      } else {
        resolve();
      }
    });

    loaded.then(() => {
      if (!store.currentPlayer) {
        props.onFinish();
        return;
      }
      
      api.get(`game_sessions/${roomKey}/players/${store.currentPlayer.session_key}`).then(res => {
        autorun(() => {
          store.currentPlayer?.juice_text && setJuice(store.currentPlayer.juice_text);
        });
        
        const { data: { emotion_table_filled, juice_text } } = res;
        console.log(res, juice_text);
        if (!juice_text) {
          setJuice(`${emotion_table_filled['Образ']} со вкусом ${emotion_table_filled['Вкус']} и ароматом ${emotion_table_filled['Аромат']}, который ${emotion_table_filled['Ощущение']} со звуком ${emotion_table_filled['Звук']}`);
          saveJuice(`${emotion_table_filled['Образ']} со вкусом ${emotion_table_filled['Вкус']} и ароматом ${emotion_table_filled['Аромат']}, который ${emotion_table_filled['Ощущение']} со звуком ${emotion_table_filled['Звук']}`);
        }
      });
    });
  }
  
  const saveJuice = (text?: string) => {
    wsApi.sendEvent(EventPlayerChangeJuiceText.type, { juice_text: text || juice, player_key: store.currentPlayer!.session_key }, true);
  }

  React.useEffect(loadGameData, []);

  return (
    <CenteredSection style={{width: '96vw', background: `url(${bgDandelion}) right bottom no-repeat`, padding: '0 18vw'}}>
      <StyledHeader>
        <h1>Сок из одуванчиков</h1>
        <h2 style={{fontWeight: 'normal'}}>Отредактируйте получившийся текст</h2>
      </StyledHeader>
      { store.loaded && <StyledTextArea value={juice} onChange={e => setJuice(e.currentTarget.value)} onBlur={() => saveJuice()} /> }
      <Button onClick={props.onFinish}>Волшебная мантра</Button>
    </CenteredSection>
  )
});
