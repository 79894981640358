import styled from 'styled-components';

interface Props {
  disabled?: boolean
}

const ButtonSmall = styled.button<Props>`
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  padding: .5rem .5rem;
  border: none;
  font-weight: bold;
  color: white;
  cursor: pointer;
  background-color: #FDB028;
  box-shadow: 0px 4px 5px rgba(16, 156, 241, 0.1);
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.1s ease-in-out;
  ${props => props.disabled && 'pointer-events: none;'}

  &:hover {
    background-color: #E79C18;
  }

  &.disabled {
    background-color: gray;
    cursor: default;
  }
`;

export default ButtonSmall; 