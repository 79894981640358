import styled from 'styled-components';
import React from 'react';
import ButtonSmall from './base/ButtonSmall';
import { Link } from 'react-router-dom';

const HeaderStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0px;
    top: 0px;
    border-bottom: 1px solid rgb(194, 194, 194);
    z-index: 100;
    background-color: rgb(243, 243, 243);

  > .login{
    float: right;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    }

  > .margin{
    min-width: 20px;
    min-height: 20px;
    background-color: transparent;
  }
  .nav-link{
    font-size: 18px;
    color: #000;
    text-decoration: none;
    display: inline-block;
    padding: .5rem 1rem;
  }
  .nav-link:hover{
    color: blue;
    cursor: pointer; 
  }
`;


class Header extends React.Component {

    onExitToggel(){
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        window.location.reload();
    }

    render(){
        let login  = localStorage.getItem('login'); 
        const btnLogOut = login ? <ButtonSmall onClick={() => this.onExitToggel()}>выйти</ButtonSmall> : <div>Вы не авторизировались</div> 
        const adminLink = login ? <a className="nav-link" href="/s-admin/players/">Администрирование</a> : null;
        const content = this.props.children;
        return(
            <React.Fragment>
            <HeaderStyled>
                <div className="nav-link">
                    {adminLink}
                </div>
                <div className="login">
                    { login }
                    { btnLogOut }
                </div>
            </HeaderStyled>
            { content }
            </React.Fragment>
        )
    }
}
export default Header;
