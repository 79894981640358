import {fabric} from 'fabric';
import { GameObject } from './gameObject';
import { hexagonPointMap } from '../utils';
import leavesIcon from '../../assets/leaves.svg';
import flowerIcon from '../../assets/flower.svg';

export const COLOR_VACANT = '#e2e2e2';
export const COLOR_PLACED = '#A0D95A';
export const COLOR_VACANT_HIGHLIGHTED = '#bef5e1';
export const COLOR_VACANT_HIGHLIGHTED_ERROR = '#efbcc2';

export enum TileType {
  Vacant,
  Placed,
  Available
}

let leavesBase: fabric.Image | null = null;

export class Tile extends GameObject {
  type: TileType = TileType.Vacant;
  flowers: (GameObject | null)[] = [null, null, null, null, null, null];
  leaves!: fabric.Image;
  objectGroup: fabric.Group;
  flowersTaken: boolean[] = [false, false, false, false, false, false];

  constructor(canvas: fabric.Canvas, type: TileType, x = 0, y = 0, side = 20, flowers?: number[]) {
    super(new fabric.Polygon(hexagonPointMap(side), {
      left: x,
      top: y
    }), canvas, false);

    this.setType(type);

    this.objectGroup = new fabric.Group([this.object], {
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasControls: false,
      hasBorders: false,
      clipPath: this.object
    });
    
    if (type !== TileType.Vacant) {
      flowers?.forEach(index => {
        this.flowersTaken[index] = true;
      });

      if (leavesBase === null) {
        fabric.Image.fromURL(leavesIcon, img => {
          leavesBase = img;
          this.addLeaves(side).then(() => {
            this.setFlowers(x, y, side, flowers!);
          });
        });
      } else {
        this.addLeaves(side).then(() => {
          this.setFlowers(x, y, side, flowers!);
        });
      }
    }

    this.canvas.add(this.objectGroup);
  }

  addLeaves(side: number) {
    return new Promise((resolve, reject) => {
      if (leavesBase === null) return reject();

      leavesBase.clone((clone: fabric.Image) => {
        this.leaves = clone;
  
        const width = side * Math.sqrt(3) / 2;
        const height = side * 2;
        this.leaves.scaleToWidth(width);
        this.leaves.scaleToHeight(height);
        this.leaves.set({
          left: this.x + this.object.left!,
          top: this.y + this.object.top!,
          originX: 'center',
          originY: 'center'
        });
        this.leaves.setCoords();
  
        this.objectGroup.addWithUpdate(this.leaves);
        this.canvas.requestRenderAll();
        resolve();
      });
    });
  }

  setFlowers(x: number, y: number, side: number, flowers: number[]) {
    flowers.forEach(index => {
      this.setFlower(index, x, y, side);
      this.flowers[index]?.object.bringToFront();
    });
  }

  setFlower(index: number, x: number, y: number, side: number, inactive = false, posRelative = false, allFlowers = false) {
    this.flowers[index]?.destroy();

    let fx: number, fy: number;
    switch(index) {
      case 0:
        [fx, fy] = [x, y - side];
        break;
      case 1:
        [fx, fy] = [x + side * Math.sqrt(3) / 2, y - side * 0.5];
        break;
      case 2:
        [fx, fy] = [x + side * Math.sqrt(3) / 2, y + side * 0.5];
        break;
      case 3:
        [fx, fy] = [x, y + side];
        break;
      case 4:
        [fx, fy] = [x - side * Math.sqrt(3) / 2, y + side / 2];
        break;
      case 5:
        [fx, fy] = [x - side * Math.sqrt(3) / 2, y - side / 2];
        break;
    }

    fabric.Image.fromURL(flowerIcon, img => {
      img.scaleToWidth(side);
      img.scaleToHeight(side);
      img.set({
        top: (posRelative ? this.objectGroup.top! : 0) + fy,
        left: (posRelative ? this.objectGroup.left! : 0) + fx,
        opacity: inactive ? 0 : 1
      });

      this.flowers[index] = new GameObject(img, this.canvas, false);
      if (allFlowers) {
        if (this.flowers.findIndex(flower => flower === null) === -1) {
          this.flowers.forEach(flower => {
            this.objectGroup.addWithUpdate(flower!.object);
          })
        }
      } else {
        this.objectGroup.addWithUpdate(this.flowers[index]!.object);
      }
      this.canvas.requestRenderAll();
    });
  }

  setType(type: TileType) {
    let fill;

    this.type = type;
    switch (type) {
      case TileType.Vacant:
        fill = COLOR_VACANT;
        break;
      case TileType.Placed:
      case TileType.Available:
        fill = COLOR_PLACED;
    }
    this.object.set({ fill });
    this.canvas.requestRenderAll();
  }

  get x() {
    return this.objectGroup.left!;
  }

  set x(value) {
    this.objectGroup.set({ left: value });
    this.canvas.requestRenderAll();
  }
  
  get y() {
    return this.objectGroup.top!;
  }

  set y(value) {
    this.objectGroup.set({ top: value });
    this.canvas.requestRenderAll();
  }
  
  destroy() {
    this.canvas.remove(this.objectGroup, ...this.objectGroup.getObjects());
  }
}