import React, { useState } from 'react';
import Button from '../components/base/Button';
import {Link} from 'react-router-dom';
import CenteredSection from '../components/base/CenteredSection';
import FormItem from '../components/FormItem';
import Input from '../components/base/Input';
import store from '../store/roomStore';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const StyledVersioning = styled.div`
  position: absolute;
  bottom: 5vh;
  left: 5vh;
`;

export default observer(() => {
  const [key, setKey] = useState('');

  return (
    <CenteredSection>
      <h1>Выбор комнаты</h1>
      <FormItem label="Ключ комнаты" labelWidth="150px">
        <Input type="text" onInput={(e: React.ChangeEvent<HTMLInputElement>) => setKey(e.target.value)} />
      </FormItem>
      <Link to={`/${key}`}>
        <Button>Присоединиться</Button>
      </Link>
      <div>&nbsp;</div>
      { store.isAdmin && <Link to={`/${key}?spectate`}>
        <Button>Присоединиться как наблюдатель</Button>
      </Link> }
      <div>&nbsp;</div>
      { !store.isAdmin && <Link to="/auth">
        <Button>Войти как администратор</Button>
      </Link> }
      <div>&nbsp;</div>
      { store.isAdmin && <Link to="/list">
        <Button>Список игр</Button>
      </Link> }
      <StyledVersioning>
        created by <a href="http://improve.studio" target="_blank" rel="noopener noreferrer">improve.studio</a> <br/>
        {process.env.REACT_APP_VERSION}{process.env.REACT_APP_BUILD_VERSION ? '.' + process.env.REACT_APP_BUILD_VERSION : ""}
      </StyledVersioning>
    </CenteredSection>
  )
});
