import { useLocation } from "react-router-dom";

export const hexagonPointMap = (side: number) => [
  { x: side * Math.sqrt(3) / 2, y: 0 },
  { x: side * Math.sqrt(3), y: side * 0.5 },
  { x: side * Math.sqrt(3), y: side * 1.5 },
  { x: side * Math.sqrt(3) / 2, y: side * 2 },
  { x: 0, y: side * 1.5 },
  { x: 0, y: side * 0.5 }
];

export const useQuery = () => new URLSearchParams(useLocation().search);
