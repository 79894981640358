import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Game from './pages/Game';
import PreRoomEnter from './pages/PreRoomEnter';
import Auth from './pages/Auth';
import GameList from './pages/GameList';
import CreateSession from './pages/CreateSession';
import Header from './components/Header';

export default function() {
  const routes = [
    {
      path: '/auth',
      component: <Auth />
    },
    {
      path: '/list/create',
      component: <CreateSession />
    },
    {
      path: '/list',
      component: <GameList />
    },
    {
      path: '/:roomKey',
      component: <Game />
    },
    {
      path: '/',
      component: <PreRoomEnter />
    },
    {
      path: '*',
      component: <p>404 page not found</p>
    }
  ]

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(route => {
          if( (route.path === '/') || (route.path === '/list') || (route.path === '/list/create') ){
            return(
              <Route path={route.path} key={route.path}>
                <Header>
                  { route.component }
                </Header>
              </Route>
            )
          }
          return(
            <Route path={route.path} key={route.path}>
                { route.component }
            </Route>
          )
        })}
      </Switch>
    </BrowserRouter>
  );
}
