import {fabric} from 'fabric';

export class GameObject {
  object: fabric.Object;
  canvas: fabric.Canvas;

  constructor(object: fabric.Object, canvas: fabric.Canvas, addToCanvas = true) {
    this.object = object;
    this.object.set({
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasControls: false,
      hasBorders: false
    });
    this.canvas = canvas;
    
    if (addToCanvas) this.canvas.add(this.object);
  }

  get x() {
    return this.object.left!;
  }

  set x(value) {
    this.object.set({left: value});
    this.canvas.requestRenderAll();
    // The following line fixes a weird bug with canvas off-screen objects not rendering
    this.object.setCoords();
  }

  get y() {
    return this.object.top!;
  }

  set y(value) {
    this.object.set({top: value});
    this.canvas.requestRenderAll();
    // The following line fixes a weird bug with canvas off-screen objects not rendering
    this.object.setCoords();
  }

  destroy() {
    this.canvas.remove(this.object);
  }
}