import React, { useEffect } from 'react';
import CenteredSection from '../base/CenteredSection';
import styled from 'styled-components';
import imgQuote from '../../assets/quote.svg';
import wsApi from '../../api/wsApi';
import { EventPlayerGiveQuoteCard } from '../../api/wsEvents';

interface Props {
  onFinish(): any
}

interface CardProps {
  index: number
}

const StyledText = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 1.8;

  .embedded {
    color: #9b8ade;
  }
`;

const StyledCards = styled.div`
  position: relative;
  margin-top: 50px;

  > img {
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

const StyledCard = styled.img`
  left: ${(props: CardProps) => 100 - 50 * props.index}px;
  top: ${(props: CardProps) => (Math.abs(props.index - 2) ** 2) * 7}px;
  transform-origin: top center;
  transform: rotate(${(props: CardProps) => 10 * props.index - 30}deg) scale(1.5);

  &:hover {
    z-index: 1;
    border-radius: 3px;
    top: -1rem;
  }
`;

const StyledRoot = styled(CenteredSection)`
  &.fading {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
`;

export default function ModalChooseQuote(props: Props) {
  const rootRef = React.createRef<HTMLElement>();
  const [player, setPlayer] = React.useState('');

  useEffect(() => {
    setPlayer(JSON.parse(localStorage.getItem('last-helped-player') || '{}').name || 'Какой-то игрок')
  }, []);

  const sendQuote = () => {
    wsApi.sendEvent(EventPlayerGiveQuoteCard.type, { player_key: JSON.parse(localStorage.getItem('last-helped-player') || '{}').key }, true);
    rootRef.current?.classList.add('fading');
    rootRef.current?.addEventListener('transitionend', props.onFinish);
  }

  return (
    <StyledRoot ref={rootRef}>
      <StyledText>Игрок <span className="embedded">"{player}"</span> помог вам! <br/>Ваша благодарность – это Ваша поддержка и… «Вино из одуванчиков» от Рэя Брэдбери!</StyledText>
      <StyledCards>
        { [0, 1, 2, 3, 4].map(index => (
          <StyledCard 
            key={index}
            index={index}
            src={imgQuote} 
            alt="карточка с цитатой" 
            onClick={sendQuote}
          />
        )) }
      </StyledCards>
    </StyledRoot>
  );
}
