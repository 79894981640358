import {fabric} from 'fabric';
import {GameField} from './gameField';
import { Vector2 } from '../entities/game/Vector2';
import { Tile, COLOR_VACANT, COLOR_VACANT_HIGHLIGHTED, TileType, COLOR_VACANT_HIGHLIGHTED_ERROR } from './gameObjects/tile';
import * as eventTypes from '../api/wsEvents';
import wsApi from '../api/wsApi';
import store, { PlayerTurnPhases, ViewPhase } from '../store/roomStore';
import { autorun } from 'mobx';
import { Chip } from './gameObjects/chip';
import { createGlobalStyle } from 'styled-components';

type EventListener = (e: fabric.IEvent) => void;

let mainCanvas: fabric.Canvas;
let gameField: GameField;
let playerChip: Chip;
const baseCanvasOffset = {x: 0, y: 0};

function init() {
  const canvasElement = document.getElementById('canvas-main') as HTMLCanvasElement;
  mainCanvas = new fabric.Canvas(canvasElement, {
    selection: false,
    hoverCursor: 'default',
    fireRightClick: true,
    backgroundColor: 'rgba(226, 240, 221, 0.2)'
  });
  mainCanvas.setDimensions({
    width: canvasElement?.getAttribute('data-width')!,
    height: canvasElement?.getAttribute('data-height')!
  });
  mainCanvas.calcOffset();


  mainCanvas.viewportTransform![4] += mainCanvas.getWidth() / 2;
  mainCanvas.viewportTransform![5] += mainCanvas.getHeight() / 2;

  baseCanvasOffset.x = mainCanvas.viewportTransform![4];
  baseCanvasOffset.y = mainCanvas.viewportTransform![5];

  gameField = new GameField(mainCanvas);

  autorun(() => {
    if (!!playerChip) return;
    if (store.currentPlayer) {
      playerChip = gameField.generateChip(0, 0, store.currentPlayer!.chip?.color || 'gray', store.currentPlayer!.session_key);
    }
	});
	
	autorun(() => {
		if (!playerChip || !store.currentPlayer) return;

		playerChip.setColor(store.heldForeignChip?.color ?? store.currentPlayer?.chip?.color);
	});
  
  const mouseUpListeners: EventListener[] = [
    // Tile placement
    e => {
      if (gameField.availableTile?.isDragged && gameField.getTileByGlobal(e.pointer!.x, e.pointer!.y)?.type === TileType.Vacant) {
        const localCoords = gameField.globalToLocal({x: e.pointer!.x, y: e.pointer!.y});
        if (gameField.canAddTile(localCoords.x, localCoords.y, gameField.availableTile?.getFlowersArray())) {
          const {x, y} = gameField.globalToLocal({x: e.pointer!.x, y: e.pointer!.y});
          const puzzle = {
            x,
            y,
            vertices_state: gameField.availableTile.getFlowersArray().reduce((acc, number) => {
              acc[number] = true;
              return acc;
            }, [false, false, false, false, false, false]),
            id: gameField.availableTile!.id
          };

          wsApi.sendEvent(eventTypes.EventFieldSetPuzzle.type, {
            puzzle
          }, true);

          gameField.addTile(x, y, TileType.Placed, gameField.availableTile.getFlowersArray());
          gameField.availableTile?.destroy();
        } else {
          gameField.resetAvailableTilePosition();
          gameField.availableTile?.syncPosition(baseCanvasOffset);
        }
      } else if (gameField.availableTile?.isDragged) {
        gameField.availableTile?.syncPosition(baseCanvasOffset);
      }
    },
    // Chip placement
    e => {
			if (!store.isDraggingChip) return;
      store.isDraggingChip = false;
			if ((store.heldForeignChip === null && store.playerTurnPhase !== PlayerTurnPhases.PlacingExtraChip && store.playerTurnPhase !== PlayerTurnPhases.PlacedPuzzle) || !gameField.isCornerAvailable(playerChip.vertice, playerChip.intersectingTiles)) {
				store.heldForeignChip = null;
				return;
			}

			const {x, y} = gameField.getClosestCorner(e.pointer!.x, e.pointer!.y)[0];

      const flowerBlacklist: Vector2[] = JSON.parse(localStorage.getItem('flower-blacklist') || '[]');
      if (flowerBlacklist.includes({ x: Math.floor(x), y: Math.floor(y) })) {
        return;
      }

      if (Object.values(store.placedChips).some((chip) => chip.x === Math.floor(x) && chip.y === Math.floor(y))) {
        return;
      }

      playerChip.disable();
			
      wsApi.sendEvent(eventTypes.EventFieldSetChip.type, {
        chip: {
          x: Math.floor(x),
          y: Math.floor(y),
          color: playerChip.color
				},
				chip_owner_key: store.heldForeignChip?.ownerKey ?? store.currentPlayer?.session_key
			}, true);
			
			if (store.heldForeignChip !== null) {
				store.heldForeignChip = null;
				return;
			}

      if (store.playerTurnPhase === PlayerTurnPhases.PlacingExtraChip) {
        store.playerTurnPhase = PlayerTurnPhases.TurnStart;
      } else {
        store.endTurn();
      }
    }
  ];

  let highlightedTile: Tile | undefined = undefined;

  const mouseMoveListeners: EventListener[] = [
    // Tile highlighting
    e => {
      if (highlightedTile?.type === TileType.Vacant) {
        highlightedTile?.object.setColor(COLOR_VACANT);
      }

      const tile = gameField.getTileByGlobal(e.pointer!.x, e.pointer!.y);
      if (tile && tile.type === TileType.Vacant) {
        const localCoords = gameField.globalToLocal({x: e.pointer!.x, y: e.pointer!.y});
        tile!.object.setColor(
          gameField.canAddTile(localCoords.x, localCoords.y, gameField.availableTile?.getFlowersArray()) 
          ? COLOR_VACANT_HIGHLIGHTED 
          : COLOR_VACANT_HIGHLIGHTED_ERROR
        );
        highlightedTile = tile;
      }
      mainCanvas.requestRenderAll();
    },
    // Chip movement
    e => {
      if (!store.isDraggingChip) return;
      
      playerChip.disabled && playerChip.enable();
      const [{x, y}, intersectingTiles, vertice] = gameField.getClosestCorner(e.pointer!.x, e.pointer!.y);
      playerChip.x = x;
      playerChip.y = y;
      playerChip.intersectingTiles = intersectingTiles;
      playerChip.vertice = vertice;

      if (gameField.isCornerAvailable(playerChip.vertice, playerChip.intersectingTiles)) {
        playerChip.object.set({opacity: 0.7});
      } else {
        playerChip.object.set({opacity: 0.4});
      }
    }
  ];

  initCamera(mainCanvas, gameField, mouseUpListeners, mouseMoveListeners);
}

// We wrap all functions inside camera handling methods to ensure they aren't called while moving camera and also to fix coordinate problems
function initCamera(canvas: fabric.Canvas, gameField: GameField, mouseUpListeners: EventListener[], mouseMoveListeners: EventListener[]) {
  let isDragging = false;
  let prevPointer: Vector2 = { x: 0, y: 0 };
  
  function drag(e: fabric.IEvent) {
    isDragging = true;
    gameField.shiftUI(e.pointer!.x - prevPointer.x, e.pointer!.y - prevPointer.y);
    canvas.viewportTransform![4] += e.pointer!.x - prevPointer.x;
    canvas.viewportTransform![5] += e.pointer!.y - prevPointer.y;
    prevPointer = e.pointer!;
    canvas.requestRenderAll();
  }
  
  canvas.on('mouse:down', e => {
    isDragging = e.button === 3;
    prevPointer = Object.assign({}, e.pointer!);
  });

  canvas.on('mouse:move', e => {
    if (isDragging) {
      drag(e);
      return;
    }

    e.pointer!.x -= canvas.viewportTransform![4];
    e.pointer!.y -= canvas.viewportTransform![5];
    mouseMoveListeners.forEach(listener => listener(e));
  })

  canvas.on('mouse:up', e => {
    if (!isDragging) {
      e.pointer!.x -= canvas.viewportTransform![4];
      e.pointer!.y -= canvas.viewportTransform![5];
      mouseUpListeners.forEach(listener => listener(e));
    }
    canvas.off('mouse:move', drag);
    isDragging = false;
  });
}

function dispose() {
  mainCanvas?.dispose();
  gameField?.dispose();
}

function useWebSocket(path: string) {
  return new Promise<void>((resolve, reject) => {
    const onopen = function(this: WebSocket, e: Event) {
      this.send(JSON.stringify({
        key: localStorage.getItem('session_key')
      }));
      resolve();
    }
  
    const onmessage = function(this: WebSocket, e: MessageEvent) {
      const {event_type, data, sender_key, error} = JSON.parse(e.data);
      console.log('Message received: ', e.data);
      if (error === 1) {
        alert('Не удалось подключиться к игре! Попробуйте перезагрузить страницу');
      }
      
      if (event_type == null) return true;
			
      const eventEntry = Object.entries(eventTypes).find(([key, value]) => value.type === event_type);
      if (!eventEntry) return true;

      const event = new eventEntry[1](mainCanvas, gameField, {...data, sender_key}, baseCanvasOffset);
      event?.act();
    }
    
    wsApi.init(path, onopen, onmessage);
  });
}

export default {
  init, dispose, useWebSocket
};
