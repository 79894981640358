import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../base/Button';
import Input from '../base/Input';
import CenteredSection from '../base/CenteredSection';
import FormItem from '../FormItem';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import api from '../../api/api';
import game from '../../game/main';
import store from '../../store/roomStore';
import wsApi from '../../api/wsApi';
import { EventPlayerChangeRequestForm } from '../../api/wsEvents';
import { autorun, toJS } from 'mobx';

interface RouteParams {
  roomKey: string
}

interface Props {
	onFinish(): any
}

const StyledForm = styled.form`
  width: 50%;
  margin: 20px auto;
`;

export default function Register(props: Props) {
  const [formData, setFormData] = useState({
    name: '',
    situation: '',
    resource1: '',
    resource2: '',
    resource3: '',
    situationDevelopment: ''
  });
  const { roomKey } = useParams<RouteParams>();

  const setFormItem = (key: string, value: string) => {
    setFormData({...formData, [key]: value});

    const newFormData = {...formData, [key]: value};
    store.currentPlayer!.name = newFormData.name;
    store.currentPlayer!.form_data = { 
      ...store.currentPlayer?.form_data, 
      name: newFormData.name || '',
      situation: newFormData.situation || '',
      lost_resources: [newFormData.resource1 || '', newFormData.resource2 || '', newFormData.resource3 || ''],
      situation_result: newFormData.situationDevelopment || ''
    };
  }

  const sendData = () => {
    wsApi.sendEvent(EventPlayerChangeRequestForm.type, {
      name: formData.name || '',
      situation: formData.situation || '',
      lost_resources: [formData.resource1 || '', formData.resource2 || '', formData.resource3 || ''],
      situation_result: formData.situationDevelopment || ''
    });
  }

  const finish = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!formData.name || !formData.situation || !formData.resource1 || !formData.resource2 || !formData.resource3 || !formData.situationDevelopment) {
      alert('Вы должны заполнить все поля для продолжения!');
      return;
    }
    sendData();
    props.onFinish();
  }

  useEffect(() => {
    const loaded = new Promise(resolve => {
      if (localStorage.getItem('room_key') !== roomKey) {
        api.post(`rooms/${roomKey}/members`).then(res => {
          localStorage.setItem('session_key', res.data.session_key);
          localStorage.setItem('room_key', roomKey);
          resolve();
        }).catch(err => {
          alert('Не удалось присоединиться к комнате');
        });
      } else {
        resolve();
      }
    });

    loaded.then(() => {
      store.fetchRoom(roomKey).then(() => {
        if (store.isKicked) return;
        game.useWebSocket(api.defaults.baseURL!.replace('http', 'ws') + `game-stream/${roomKey}`).then(() => {
          game.dispose();
          game.init();
        });
      });
    })
  }, [roomKey]);

  useEffect(() => {
    autorun(() => {
      // This is required for proper updates. Don't ask.
      toJS(store.currentPlayer);
      if (store.currentPlayer?.form_data) {
        const renamedFormData = {
          name: store.currentPlayer.form_data.name, 
          situation: store.currentPlayer.form_data.situation,
          resource1: store.currentPlayer.form_data.lost_resources && store.currentPlayer.form_data.lost_resources[0],
          resource2: store.currentPlayer.form_data.lost_resources && store.currentPlayer.form_data.lost_resources[1],
          resource3: store.currentPlayer.form_data.lost_resources && store.currentPlayer.form_data.lost_resources[2],
          situationDevelopment: store.currentPlayer.form_data.situation_result
        }
        
        setFormData(renamedFormData);
      }
    });
  }, []);

  return (
    <CenteredSection>
      <h1>Заполните информацию</h1>
      <StyledForm onSubmit={finish}>
        <FormItem labelWidth="30%" label="Меня зовут">
          <Input type="text" value={formData.name} onChange={e => setFormItem('name', e.currentTarget.value.slice(0, 40))} onBlur={sendData} placeholder="Светлана" />
        </FormItem>
        <FormItem labelWidth="30%" label="Ситуация, которая доставляет мне беспокойство">
          <textarea value={formData.situation} onChange={e => setFormItem('situation', e.currentTarget.value.slice(0, 1e+5))} onBlur={sendData} placeholder="Введите текст" />
        </FormItem>
        <FormItem labelWidth="30%" label="Ресурсы, которые я теряю">
          <Input type="text" value={formData.resource1} onChange={e => setFormItem('resource1', e.currentTarget.value.slice(0, 100))} onBlur={sendData} placeholder="Ресурс 1" />
        </FormItem>
        <FormItem labelWidth="30%">
          <Input type="text" value={formData.resource2} onChange={e => setFormItem('resource2', e.currentTarget.value.slice(0, 100))} onBlur={sendData} placeholder="Ресурс 2" />
        </FormItem>
        <FormItem labelWidth="30%">
          <Input type="text" value={formData.resource3} onChange={e => setFormItem('resource3', e.currentTarget.value.slice(0, 100))} onBlur={sendData} placeholder="Ресурс 3" />
        </FormItem>
        <FormItem labelWidth="30%" label="Развитие этой ситуации">
          <textarea value={formData.situationDevelopment} onChange={e => setFormItem('situationDevelopment', e.currentTarget.value.slice(0, 1e+5))} onBlur={sendData} placeholder="Введите текст" />
        </FormItem>
        <FormItem labelWidth="30%">
					<Button>Следующий шаг</Button>
        </FormItem>
      </StyledForm>
    </CenteredSection>
  )
}
