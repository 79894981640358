import React, { useState } from 'react';
import CenteredSection from '../base/CenteredSection';
import bgButterfly1 from '../../assets/bg-butterfly-1.svg';
import bgButterfly2 from '../../assets/bg-butterfly-2.svg';
import dandelionAttachment from '../../assets/bg-dandelion-attachment.svg';
import imgMantra from '../../assets/img-mantra.svg';
import styled from 'styled-components';
import Button from '../base/Button';
import Input from '../base/Input';
import store from '../../store/roomStore';
import { useParams } from 'react-router-dom';
import api from '../../api/api';

interface RouteParams {
  roomKey: string
}

interface PlayerData {
  name: string,
  dandelion_count: number,
  wine_count: number,
  session_key: string,
  account: {
    id: number,
    username: string,
    role: number,
    is_active: boolean
  },
  order: number,
  chip: {
    x: number,
    y: number,
    color: string,
    state: 0
  },
  quotes: {
    text: string,
    sender: {
      name: string,
      dandelion_count: number,
      wine_count: number,
      session_key: string,
      account: {
        id: number,
        username: string,
        role: number,
        is_active: boolean
      },
      order: number
    }
  }[],
  emotion_table_filled: {
    [feeling: string]: string
  },
  form_data: {
    name: string,
    situation: string,
    lost_resources: string[],
    situation_result: string
  },
  juice_text: string
}

const StyledRoot = styled(CenteredSection)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 95vh;
  padding: 0 0 5vh;
  justify-content: flex-start;
`;

const StyledHeader = styled.h1`
  text-align: center;
  background: url(${bgButterfly1}) top left no-repeat, url(${bgButterfly2}) top right no-repeat;
  padding: 50px 0 6px;
  margin-top: 10vh;
`;

const StyledEndHeader = styled.h1`
  text-align: center;
  background: url(${bgButterfly1}) bottom left no-repeat, url(${bgButterfly2}) top right no-repeat;
  padding: 50px 0 36px;
  color: #3E8727;
`;

const StyledTextArea = styled.div`
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 100%;
  height: 40vh;
  padding: 1rem;
  margin-bottom: 1rem;

  p {
    padding: 15px 0 10px;
  }
`;

const StyledEmailBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;

  * + * {
    margin-left: 20px;
  }
`;

const StyledTextDisplay = styled.div`
  position: relative;
  height: 50vh;
  padding: 1rem;

  p {
    padding: 15px 0 10px;
  }
  
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #0A9307;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 132px), calc(100% - 2px) calc(100% - 132px), calc(100% - 2px) calc(100% - 2px), calc(100% - 54px) calc(100% - 2px), calc(100% - 54px) 100%, 0 100%);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: calc(100% - 109px);
    top: calc(100% - 150px);
    width: 175px;
    height: 210px;
    background: url(${dandelionAttachment}) center no-repeat;
  }
`;

const StyledEndWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMantraImage = styled.img`
  flex-basis: 45%;
  padding-left: 50px;
  max-height: 70vh;
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

export default function ModalMantra() {
  const { roomKey } = useParams<RouteParams>();
  const [isSent, setIsSent] = useState(false);
  const [playerData, setPlayerData] = useState(null as PlayerData | null);
  const [email, setEmail] = useState('');

  const loadGameData = () => {
    const loaded = new Promise((resolve) => {
      if (!store.loaded && !store.loaded) {
        store.fetchRoom(roomKey).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });

    loaded.then(() => {
      if (!store.currentPlayer) {
        return;
      }
      
      api.get(`game_sessions/${roomKey}/players/${store.currentPlayer.session_key}`).then(res => {
        setPlayerData(res.data);
      });
    });
  }

  const sendToEmail = () => {
    setIsSent(true);
    if (!store.currentPlayer) return;

    api.post(`game_sessions/${roomKey}/players/${store.currentPlayer.session_key}/send_result`, null, { params: { email } });
  }

  React.useEffect(loadGameData, []);

  return (
    <StyledRoot style={{width: '60%'}}>
      { !isSent ? (
        <>
          <StyledHeader>Волшебная мантра</StyledHeader>
          <StyledTextArea>
            <p className="bold">Вселенная, благодарю тебя за предупреждение о том, что</p>
            <div>{playerData?.form_data?.situation_result || ' '}</div>
            <p className="bold">и посылаю тебе</p>
            <div>{playerData?.form_data?.lost_resources?.join(' ') || ' '}</div>
            <p className="bold">в виде</p>
            <div>{playerData?.juice_text || ' '}</div>
          </StyledTextArea>
          <StyledEmailBar>
            <span>Введите свой e-mail</span>
            <Input type="email" placeholder="email@yandex.ru" value={email} onChange={e => setEmail(e.currentTarget.value)} />
            <Button onClick={sendToEmail}>Отправить на e-mail</Button>
          </StyledEmailBar>
          <StyledFooter>
            <span>Автор идеи и разработчик игры:<br/>Ирина Стасевич</span>
            <span>г. Пермь<br/><a href="mailto:sirenas@bk.ru">sirenas@bk.ru</a></span>
          </StyledFooter>
        </>
      ) : (
        <>
          <StyledEndHeader>Вино из одуванчиков</StyledEndHeader>
          <StyledEndWrapper>
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <StyledTextDisplay>
                <p className="bold">Вселенная, благодарю тебя за предупреждение о том, что</p>
                <div>{playerData?.form_data.situation_result || ' '}</div>
                <p className="bold">и посылаю тебе</p>
                <div>{playerData?.form_data.lost_resources.join(' ') || ' '}</div>
                <p className="bold">в виде</p>
                <div>{playerData?.juice_text || ' '}</div>
              </StyledTextDisplay>
              <StyledFooter>
                <span>Автор идеи и разработчик игры:<br/>Ирина Стасевич</span>
                <span>г. Пермь<br/><a href="mailto:sirenas@bk.ru">sirenas@bk.ru</a></span>
              </StyledFooter>
            </div>
            <StyledMantraImage src={imgMantra} />
          </StyledEndWrapper>
        </>
      ) }
    </StyledRoot>
  )
}
