import * as mobx from 'mobx';
import api from '../api/api';
import { EventGameStartRound } from '../api/wsEvents';
import wsApi from '../api/wsApi';

const {observable, action, computed} = mobx;

interface Chip {
  x: number | null,
  y: number | null,
  color: string | null
}

export interface Player {
  name: string,
  dandelion_count: number,
  wine_count: number,
  session_key: string,
  account: {
    id: number,
    username: string,
    role: number,
    is_active: boolean
  },
  order: number,
  chip: {
    x: number,
    y: number,
    color: string,
    state: 0
  },
  quotes: {
    text: string,
    sender: {
      name: string,
      dandelion_count: number,
      wine_count: number,
      session_key: string,
      account: {
        id: number,
        username: string,
        role: number,
        is_active: boolean
      },
      order: number
    }
  }[],
  emotion_table_filled: {
    [feeling: string]: string
  },
  form_data: {
    name: string,
    situation: string,
    lost_resources: string[],
    situation_result: string
  },
  juice_text: string,
  game_mode: PlayerGameMode,
  is_kicked: boolean,
  round_state: PlayerTurnPhases
}

export interface HexPuzzle {
  x: number,
  y: number,
  vertices_state: boolean[],
  id: number
}

export enum PlayerTurnPhases {
  TurnStart, PlacingPuzzle, PlacedPuzzle, OtherPlayerTurn, PlacingExtraChip, AwaitingPlacingExtraChip
}

export enum RoomStatus {
  Waiting = 0, Started = 1, Paused = 2, Finished = 3
}

export enum ViewPhase {
  Register, ChooseChip, Play,
  OpeningFeeling, ViewingDandelionTable, ChoosingQuote,
  EditingJuice, EditingMantra
}

export enum PlayerGameMode {
  Player = 0, AdminPlayer = 1, AdminSpectator = 2
}

class RoomStore {
  @observable roomName: string = '';
  @observable roomMaxPlayers: number = 0;
  @observable roomTimes: string = '';
  @observable players: Player[] = [];
  @observable spectators: Player[] = [];
  @observable currentPlayer: Player | null = null;
  @observable loading = false;
  @observable loaded = false;
  @observable isDraggingChip = false;
  @observable field: HexPuzzle[] = [];
  @observable actingPlayerSessionKey = '';
  @observable assignedChips: {
    [session_key: string]: string
  } = {};
  @observable isAdmin = false;
  @observable isTopPuzzleFlipped = false;
  @observable topPuzzle: HexPuzzle | null = null;
  @observable playerTurnPhase: PlayerTurnPhases = PlayerTurnPhases.OtherPlayerTurn;
  @observable roomStatus: RoomStatus = RoomStatus.Waiting;
  @observable viewPhase: ViewPhase = parseInt(localStorage.getItem('phase') || ViewPhase.Play.toString());
  @observable deckSize: number = 72;
  @observable placedChips: {
    [session_key: string]: Chip
  } = {};
  @observable receivingPlayersList: string[] = [];
  @observable quotes: {
    text: string,
    sender: Player
  }[] = [];
	@observable isKicked = false;
  @observable heldForeignChip: null | { ownerKey: string, color: string } = null;

  constructor() {
    this.isAdmin = !!localStorage.getItem('token');

    mobx.autorun(() => {
      localStorage.setItem('phase', this.viewPhase.toString());
    });
  }

  @computed get isSpectating() {
    return this.spectators.findIndex(spectator => spectator.session_key === this.currentPlayer?.session_key) !== -1;
  }

  @computed get chipPlaced() {
    return this.currentPlayer && Object.keys(this.placedChips).includes(this.currentPlayer.session_key);
  }

  @computed get selectedChips() {
    return Object.values(this.assignedChips);
  }

  @computed get leadingPlayerSessionKey() {
    let count = 0; // amount of players with the same score
    const res = [...this.players, this.currentPlayer].reduce((acc, player) => {
      if (!player) return acc;
      if (player.dandelion_count === acc.score) ++count;

      if (!acc.key || player.dandelion_count > acc.score) {
        count = 1;
        return {
          key: player.session_key,
          score: player.dandelion_count
        }
      } else {
        return acc;
      }
    }, {key: '', score: 0}).key;

    return count === 1 ? res : '';
  }

  @computed get activePlayers() {
    return this.players.filter(player => !player.is_kicked);
  }

  @action addPlayer(player: Player) {
    if (this.currentPlayer?.session_key === player.session_key || this.players.find(item => item.session_key === player.session_key)) return;
    this.players.push(player);
    this.assignedChips[player.session_key] = player.chip?.color || '';
  }

  @action endTurn() {
    this.playerTurnPhase = PlayerTurnPhases.OtherPlayerTurn;
    wsApi.sendEvent(EventGameStartRound.type, {}, true);
  }

  @action removePlayer(session_key: string) {
    this.players = this.players.filter(player => player.session_key !== session_key);
    delete this.assignedChips[session_key];
  }

  @action async fetchPlayerInfo(roomKey: string, playerKey: string) {
    const { data } = await api.get(`game_sessions/${roomKey}/players/${playerKey}`);
    this.currentPlayer && mobx.extendObservable(this.currentPlayer, data);

    this.quotes = data.quotes;
  }

  @action async fetchRoom(roomKey: string) {
    this.loading = true;
    const res = await api.get('game_sessions/' + roomKey + '/game_info');
    if (!res?.data) {
      this.loading = false;
      alert('Не удалось получить информацию о комнате. Попробуйте перезагрузить страницу');
      throw new Error('FETCH_GAME_INFO_ERROR');
    }
    const players = res.data['players'].filter((player: Player) => player.game_mode === PlayerGameMode.AdminPlayer || player.game_mode === PlayerGameMode.Player);
    this.spectators = res.data['players'].filter((player: Player) => player.game_mode === PlayerGameMode.AdminSpectator);

    const currentPlayerIndex = players.findIndex((player: Player) => player.session_key === localStorage.getItem('session_key'));

    this.roomName = res.data['title'];
    this.roomMaxPlayers = res.data['max_members_count'];
    this.roomTimes = new Date(Date.parse(res.data['start_dt'])).toLocaleTimeString('en-GB') + ' - ' + new Date(Date.parse(res.data['end_dt'])).toLocaleTimeString('en-GB');
    this.roomStatus = res.data['status'];

    this.deckSize = res.data['puzzle_deck_size'];
    this.field = res.data['field'];
    console.log(mobx.toJS(this.field));
    this.players = players.filter((player: Player, index: number) => index !== currentPlayerIndex);
    this.currentPlayer = players[currentPlayerIndex];

    if (!!this.currentPlayer) {
      this.isKicked  = this.currentPlayer['is_kicked'];
      this.playerTurnPhase = this.currentPlayer.round_state;
      await this.fetchPlayerInfo(roomKey, this.currentPlayer.session_key);
    }

    this.actingPlayerSessionKey = res.data['current_player']?.session_key;
    setTimeout(() => {
      this.topPuzzle = res.data['current_opened_puzzle'];
    }, 500);
    this.assignedChips = {};
    this.placedChips = {};
    [...this.players, this.currentPlayer].forEach(player => {
      if (!player) return;
      this.assignedChips[player.session_key] = player.chip?.color || '';
      // Intended == check
      if (player.chip?.x != null && player.chip?.y != null) {
        this.placedChips[player.session_key] = player.chip;
      }
    });
    this.loading = false;
    this.loaded = true;

    if (res.data['status'] === 3 || (this.deckSize === 0 && res.data['status'] > 0)) {
      this.viewPhase = ViewPhase.EditingJuice;
    }

    return res;
  }
}

const store = new RoomStore();

export default store;