import {fabric} from 'fabric';
import { GameField } from '../game/gameField';
import { TileType } from '../game/gameObjects/tile';
import { Vector2 } from '../entities/game/Vector2';
import store, {Player, PlayerTurnPhases, RoomStatus, ViewPhase, PlayerGameMode, HexPuzzle} from '../store/roomStore';
import { toJS, extendObservable } from 'mobx';
import { Chip } from '../game/gameObjects/chip';
import wsApi from './wsApi';
import { DraggableTile } from '../game/gameObjects/draggableTile';

export abstract class GameEvent {
  canvas?: fabric.Canvas;
  gameField?: GameField;
  static type: string;

  // Note that any event must follow this constructor order: canvas, gameField, data, canvasOffset with canvasOffset being optional
  constructor(canvas?: fabric.Canvas, gameField?: GameField) {
    this.canvas = canvas;
    this.gameField = gameField;
  }

  abstract act(): void;
}

export class EventFieldSetPuzzle extends GameEvent {
  puzzle: HexPuzzle;

  static type = 'Field.setPuzzle';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['puzzle'])
      throw Error('Wrong event type');

    this.puzzle = data['puzzle'];
  }

  act() {
    const flowers = this.puzzle.vertices_state.reduce((acc, value, index) => {
      if (value) acc.push(index);
      return acc;
    }, [] as number[]);
    this.gameField!.addTile(this.puzzle.x, this.puzzle.y, TileType.Placed, flowers);
    this.gameField!.availableTile?.destroy();
    store.field.push(this.puzzle);

    if (store.actingPlayerSessionKey === store.currentPlayer?.session_key) {
      this.gameField!.chips
            .filter(chip => chip.intersectingTiles.findIndex(tile => tile.x === this.puzzle.x && tile.y === this.puzzle.y) !== -1)
            .forEach(chip => {
              if (chip.ownerKey !== store.currentPlayer?.session_key) {
                wsApi.sendEvent(EventPlayerHelp.type, { player_source: store.currentPlayer?.session_key, player_target: chip.ownerKey });
              }
            });

      localStorage.setItem('last-placed-tile', JSON.stringify(this.puzzle));
      store.playerTurnPhase = PlayerTurnPhases.PlacedPuzzle;
      const collectedChips = this.gameField?.checkFlowersUnderChips();

      if (store.chipPlaced && collectedChips?.findIndex((chip: Chip) => chip.ownerKey === store.currentPlayer?.session_key) === -1) {
        store.endTurn();
      }

      if (store.deckSize === 0) {
        store.viewPhase = ViewPhase.EditingJuice;
      }
    }
    
    if (store.deckSize === 0) {
      store.viewPhase = ViewPhase.EditingJuice;
    }
  }
}

export class EventPuzzleStackMoveNext extends GameEvent {
  newPosition: Vector2;
  baseCanvasOffset: Vector2;

  static type = 'PuzzleStack.moveNext';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any, baseCanvasOffset: Vector2) {
    super(canvas, gameField);

    if (!data['newPosition'])
      throw Error('Wrong event type');

    this.newPosition = data['newPosition'];
    this.baseCanvasOffset = baseCanvasOffset;
  }

  act() {
    this.gameField!.availableTile?.translateTo(this.newPosition.x - this.canvas!.viewportTransform![4] + this.baseCanvasOffset.x, this.newPosition.y - this.canvas!.viewportTransform![5] + this.baseCanvasOffset.y);
  }
}

export class EventPuzzleStackRotate extends GameEvent {
  newRotation: number;

  static type = 'PuzzleStack.rotate';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['newRotation'])
      throw Error('Wrong event type');

    this.newRotation = data['newRotation'];
  }

  act() {
    this.gameField!.availableTile?.rotate(this.newRotation);
  }
}

export class EventFieldSetChip extends GameEvent {
  chip: {
    x: number,
    y: number,
    color: string
  }
  ownerKey: string;

  static type = 'Field.setChip';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['chip'])
      throw Error('Wrong event type');

    this.chip = data['chip'];
    this.ownerKey = data['chip_owner_key'];
  }

  act() {
    if (this.chip.x != null && this.chip.y != null && this.ownerKey !== null) {
			store.placedChips[this.ownerKey] = this.chip;
			if (store.currentPlayer?.session_key === this.ownerKey) {
				this.gameField?.checkFlowersUnderChips();
			}
    }
  }
}

export class EventGameStarted extends GameEvent {
  static type = 'Game.started';

  act() {
    if (store.roomStatus === RoomStatus.Waiting) {
      store.deckSize = 72;
    }
    store.roomStatus = RoomStatus.Started;
  }
}

export class EventGamePaused extends GameEvent {
  static type = 'Game.paused';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
  }

  act() {
    store.roomStatus = RoomStatus.Paused;
  }
}

export class EventGameStartRound extends GameEvent {
  player: {
    name: string,
    dandelion_count: number,
    wine_count: number,
    session_key: string,
    order: number
  };
  prevPhase: PlayerTurnPhases;

  static type = 'Game.startRound';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['player'])
      throw Error('Wrong event type');

    this.player = data['player'];
    this.prevPhase = data['prev_phase'];
  }

  act() {
    store.actingPlayerSessionKey = this.player.session_key;
    if (this.prevPhase === PlayerTurnPhases.PlacingPuzzle) {
      ++store.deckSize;
    }
    this.gameField?.availableTile?.destroy();
    if (store.currentPlayer?.session_key === this.player.session_key) {
      if (store.receivingPlayersList.includes(this.player.session_key)) {
        store.playerTurnPhase = PlayerTurnPhases.PlacingExtraChip;
        store.receivingPlayersList = store.receivingPlayersList.filter(key => key !== this.player.session_key);
      } else {
        store.playerTurnPhase = PlayerTurnPhases.TurnStart;
      }
    } else {
      store.playerTurnPhase = PlayerTurnPhases.OtherPlayerTurn;
    }
  }
}

export class EventPuzzleStackFlipNext extends GameEvent {
  puzzle: {
    x: number,
    y: number,
    vertices_state: boolean[],
    id: number
  }

  static type = 'PuzzleStack.flipNext';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['puzzle'])
      throw Error('Wrong event type');

    this.puzzle = data['puzzle'];
  }

  act() {
    store.topPuzzle = this.puzzle;
    --store.deckSize;
  }
}

export class EventPlayerSelectChip extends GameEvent {
  chip: {
    color: string
  };
  senderKey: string;

  static type = 'Player.selectChip';

  // Extra params for the convention to work
  constructor(_: fabric.Canvas, __: GameField, data: any) {
    super();

    if (!data['chip'])
      throw Error('Wrong event type');

    this.chip = data['chip'];
    this.senderKey = data['sender_key'];
  }

  act() {
    const player = [...store.players, store.currentPlayer].find(player => player?.session_key === this.senderKey);
    player && (player.chip = {...player.chip, color: this.chip.color});
    store.assignedChips[this.senderKey] = this.chip.color;
    console.log(toJS(store.selectedChips));
  }
}

export class EventFieldCollectFlower extends GameEvent {
  ownerKey: string;
  senderKey: string;

  static type = 'Field.collectFlower';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    if (!data['chip_owner_key'])
      throw Error('Wrong event type');

    this.ownerKey = data['chip_owner_key'];
    this.senderKey = data['sender_key'];
  }

  act() {
    const chip = this.gameField!.chips.find(item => item.ownerKey === this.ownerKey);

    if (!chip) {
      console.error('Trying to remove absent chip!');
    }

    if (chip?.ownerKey !== store.currentPlayer?.session_key) {
      chip?.animateDestroy();
    } else {
      chip?.destroy();
    }

    const player = [...store.players, store.currentPlayer].find(player => player?.session_key === this.ownerKey);
    player && ++player.dandelion_count;

    if (this.senderKey !== this.ownerKey) {
      store.receivingPlayersList.push(player!.session_key);
    }

    console.log(this.senderKey, this.ownerKey, store.receivingPlayersList);
  }
}

export class EventPlayerConnected extends GameEvent {
  player: Player;

  static type = 'Player.connected';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.player = data;
  }

  act() {
    if (this.player.game_mode !== PlayerGameMode.AdminSpectator) {
      store.addPlayer(this.player);
    }
  }
}

export class EventPlayerDisconnected extends GameEvent {
  player: Player;

  static type = 'Player.disconnected';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.player = data;
  }

  act() {
    store.removePlayer(this.player.session_key);
  }
}

export class EventPlayerChangeRequestForm extends GameEvent {
  player: Player;
  playerKey: string;

  static type = 'Player.changeRequestForm';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.playerKey = data['player_key'];
    delete data['sender_key'];
    this.player = data;
  }

  act() {
    const player = [...store.players, store.currentPlayer].find(player => player?.session_key === this.playerKey);
    
    if (player?.session_key === store.currentPlayer?.session_key) {
      extendObservable(store.currentPlayer!, { form_data: {...store.currentPlayer?.form_data, ...this.player} });
      this.player.name && extendObservable(store.currentPlayer!, {name: this.player.name});
    } else {
      player && extendObservable(player, { form_data: this.player });
      player && this.player.name && extendObservable(player, { name: this.player.name });
    }
  }
}

export class EventPlayerUnlockChip extends GameEvent {
  playerKey: string;
  senderKey: string;

  static type = 'Player.unlockChip';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.playerKey = data['player_key'];
    this.senderKey = data['sender_key'];
  }

  act() {
    const chip = this.gameField!.chips.find(item => item.ownerKey === this.playerKey);

    if (!chip) {
      if (store.players.find(player => player.session_key === this.playerKey)?.chip.x == null) {
        alert('Перелёт уже разрешён');
      }
      return;
    }

    chip.animateDestroy();
  }
}

export class EventPlayerGiveEmotionCard extends GameEvent {
  playerKey: string;
  emotion: string;

  static type = 'Player.giveEmotionCard';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
    
    this.playerKey = data['player_key'];
    this.emotion = data['emotion'];
  }

  act() {
    if (this.playerKey === store.currentPlayer?.session_key) {
      localStorage.setItem('last-emotion', this.emotion);
      if (store.viewPhase !== ViewPhase.ChoosingQuote) {
        store.viewPhase = ViewPhase.OpeningFeeling;
      }
    }
  }
}

export class EventPlayerChangeEmotionTable extends GameEvent {
  static type = 'Player.changeEmotionTable';
  player_key: string;
  table: {
    [prop: string]: string
  };

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
    
    this.player_key = data['player_key'];
    this.table = data['emotion_table'];
  }

  act() {
    const player = [...store.players, store.currentPlayer].find(player => player?.session_key === this.player_key);

    player && (player.emotion_table_filled = {...player.emotion_table_filled, ...this.table});
  }
}

export class EventPlayerGiveQuoteCard extends GameEvent {
  quote: {
    text: string,
    sender: Player
  };
  playerKey: string;
  
  static type = 'Player.giveQuoteCard';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
    
    this.quote = data['quote'];
    this.playerKey = data['player_key'];
  }

  act() {
    if (this.playerKey === store.currentPlayer?.session_key) {
      store.quotes.push(this.quote);
      ++store.currentPlayer.wine_count;
    } else {
      const player = store.players.find(player => player.session_key === this.playerKey);
      if (!player) return;

      ++player.wine_count;
    }
  }
}

export class EventPlayerChangeJuiceText extends GameEvent {
  static type = 'Player.changeJuiceText';
  playerKey: string;
  text: string;

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
    
    this.text = data['juice_text'];
    this.playerKey = data['player_key'];
  }

  act() {
    const player = [...store.players, store.currentPlayer].find(player => player?.session_key === this.playerKey);

    player && (player.juice_text = this.text);
  }
}

export class EventGamePhase1Finished extends GameEvent {
  static type = 'Game.phase1Finished';

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);
  }

  act() {
    if (!store.isSpectating) {
      store.viewPhase = ViewPhase.EditingJuice;
    }
  }
}

export class EventPlayerKicked extends GameEvent {
  static type = 'Player.kicked';
  player_key: string;

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.player_key = data['player_key'];
  }

  act() {
    if (store.currentPlayer?.session_key === this.player_key) {
      store.isKicked = true;
    } else {
      store.players = store.players.filter(player => player.session_key !== this.player_key);
    }
  }
}

export class EventPlayerNameUpdated extends GameEvent {
  static type = 'Player.NameUpdated';
  player_key: string;
  name: string;

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.player_key = data['player_key'];
    this.name = data['name'];
  }

  act() {
    if (store.currentPlayer?.session_key === this.player_key) {
      store.currentPlayer.name = store.currentPlayer.form_data.name = this.name;
    } else {
      const player = store.players.find(player => player.session_key === this.player_key);
      if (!player) return;

      player.name = this.name;
      player.form_data = { ...player.form_data, name: this.name };
    }
  }
}

export class EventPlayerHelp extends GameEvent {
  static type = 'Player.help';
  player_source: string;
  player_target: string;

  constructor(canvas: fabric.Canvas, gameField: GameField, data: any) {
    super(canvas, gameField);

    this.player_source = data['player_source'];
    this.player_target = data['player_target'];
  }

  act() {
    if (store.currentPlayer?.session_key === this.player_target) {
      localStorage.setItem('last-helped-player', JSON.stringify({
        name: store.players.find(player => player.session_key === this.player_source)?.name || 'Неизвестный игрок',
          key: this.player_source
      }));

      store.viewPhase = ViewPhase.ChoosingQuote;
    }
  }
}
