import {fabric} from 'fabric';
import { GameObject } from './gameObject';
import { autorun } from 'mobx';
import store from '../../store/roomStore';
import chipSvg from '../../assets/chip-1.svg'; 
import { Vector2 } from '../../entities/game/Vector2';

export class Chip extends GameObject {
  animationConfig: fabric.IAnimationOptions;
  color: string;
  _disabled: boolean = true;
  bringingToFront = false;
  loaded = false;
  intersectingTiles: Vector2[] = [];
  vertice: number = -1;
  ownerKey: string;
  destroyed = false;

  constructor(canvas: fabric.Canvas, x: number, y: number, color: string, ownerKey: string, width?: number) {
    super(new fabric.Image(chipSvg, {left: x, top: y}), canvas, false);
    this.color = color;
    this.ownerKey = ownerKey;

    fabric.loadSVGFromURL(chipSvg, objects => {
      const obj = fabric.util.groupSVGElements(objects);

      obj.set({
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasControls: false,
        hasBorders: false,
        left: x,
        top: y,
        fill: color,
        opacity: 0.7
      });
      
      width && obj.scaleToWidth(width);
      this.object = obj;

      this.loaded = true;
      if (this.bringingToFront) {
        this.bringToFront();
      } 

      autorun(() => {
        if ((!store.heldForeignChip && store.chipPlaced) || this.ownerKey !== store.currentPlayer?.session_key) return;

        if (store.isDraggingChip) {
          this.enable();
        } else {
          this.disable();
        }
      });
    });

    this.animationConfig = {
      onChange: () => this.canvas.requestRenderAll(),
      duration: 1000,
      easing: fabric.util.ease.easeInOutExpo
    };
	}
	
	setColor(color: string) {
		this.color = color;
		this.object.set({ fill: color });
	}

  getIntersectingCoordArrays() {
    return {
      x: this.intersectingTiles.map(point => point.x),
      y: this.intersectingTiles.map(point => point.y)
    }
  }

  bringToFront() {
    if (this.loaded) {
      this.canvas.bringToFront(this.object);
    } else {
      this.bringingToFront = true;
    }
  }

  translateTo(x: number, y: number) {
    this.object.animate('left', x, this.animationConfig);
    this.object.animate('top', y, this.animationConfig);
  }

  enable() {
    if (!this.disabled || this.destroyed) return this;

    this.canvas.add(this.object);
    this.canvas.bringToFront(this.object);
    this._disabled = false;
    return this;
  }

  disable() {
    if (this.disabled) return this;

    this.canvas.remove(this.object);
    this._disabled = true;
    return this;
  }

  get disabled() {
    return this._disabled;
  }

  animateDestroy() {
    return new Promise((resolve) => {
      this.object.animate('opacity', 0, this.animationConfig);
      this.object.animate('scaleX', 4, this.animationConfig);
      this.object.animate('scaleY', 4, this.animationConfig);
  
      setTimeout(() => {
        this.destroy();
        resolve();
      }, this.animationConfig.duration! + 1);
    });
  }

  destroy() {
    this.canvas.sendToBack(this.object);
    super.destroy();
    this.destroyed = true;
    // mobx doesnt support delete, so gotta use workaround
    // delete store.placedChips[this.ownerKey];
    store.placedChips = Object.fromEntries(Object.entries(store.placedChips).filter(entry => entry[0] !== this.ownerKey));
  }

  destroyObject() {
    this.canvas.sendToBack(this.object);
    super.destroy();
    this.destroyed = true;
  }
}