import React, { useEffect } from 'react';
import PlayerItem from './PlayerItem';
import { ReactComponent as Chip } from '../assets/chip-1.svg';
import Card from './base/Card';
import styled from 'styled-components';
import store, { PlayerTurnPhases } from '../store/roomStore';
import { observer } from 'mobx-react';
import { autorun, toJS } from 'mobx';

interface ChipProps {
  color: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  path {
    fill: ${(props: ChipProps) => props.color || 'white'};
  }
`;

export default observer(() => {
	const [currentPlayer, setCurrentPlayer] = React.useState(null as typeof store.currentPlayer);
	
	const isTapped = store.isDraggingChip && store.heldForeignChip === null;

  useEffect(() => {
    autorun(() => {
      toJS(store.currentPlayer);
      if (store.currentPlayer) {
        setCurrentPlayer({...store.currentPlayer});
      }
    });
  }, []);

  return (
    <Wrapper>
      { !store.chipPlaced && (
        <Card style={{marginRight: '10px', cursor: 'pointer', opacity: isTapped ? 0.4 : 1, border: [PlayerTurnPhases.PlacingExtraChip, PlayerTurnPhases.PlacedPuzzle].includes(store.playerTurnPhase) ? '1px solid #A0D95A' : 'none'}} onClick={e => store.isDraggingChip = !store.isDraggingChip}>
          { !isTapped
            ? <StyledChip color={currentPlayer?.chip?.color || 'gray'} style={{display: 'block', width: '3rem', height: '3rem', pointerEvents: 'none'}} />
            : <div style={{width: '3rem', height: '3rem' }} />
          }
        </Card>
      ) 
      }
      { store.currentPlayer && 
        <Card>
          <
            // @ts-ignore
            PlayerItem player={currentPlayer || {}} />
        </Card> 
      }
    </Wrapper>
  )
});
