import React, { useState } from 'react';
import CenteredSection from '../base/CenteredSection';
import styled from 'styled-components';
import store, { Player } from '../../store/roomStore';
import api from '../../api/api';
import { useParams } from 'react-router-dom';
import Input from '../base/Input';
import { ReactComponent as Chip } from '../../assets/chip-1.svg';
import { feelingColors } from './ModalDandelionsTable';
import wsApi from '../../api/wsApi';
import { EventPlayerChangeRequestForm, EventPlayerChangeEmotionTable, EventPlayerChangeJuiceText } from '../../api/wsEvents';
import { observer } from 'mobx-react';
import iconClose from '../../assets/icon-close.svg';
import { autorun, toJS } from 'mobx';

interface RouteParams {
  roomKey: string
}

interface PlayerData {
  name: string,
  dandelion_count: number,
  wine_count: number,
  session_key: string,
  account: {
    id: number,
    username: string,
    role: number,
    is_active: boolean
  },
  order: number,
  chip: {
    x: number,
    y: number,
    color: string,
    state: 0
  },
  quotes: {
    text: string,
    sender: {
      name: string,
      dandelion_count: number,
      wine_count: number,
      session_key: string,
      account: {
        id: number,
        username: string,
        role: number,
        is_active: boolean
      },
      order: number
    }
  }[],
  emotion_table_filled: {
    [feeling: string]: string
  },
  form_data: {
    name: string,
    situation: string,
    lost_resources: string[],
    situation_result: string
  },
  juice_text: string
}

const StyledTabList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

const StyledTab = styled.div`
  border: 1px solid #A0D95A;
  border-radius: 3px;
  box-shadow: 0px 4px 5px rgba(16, 156, 241, 0.1);
  padding: 7px 24px;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  &.active {
    background: #A0D95A;
    color: #fff;
  }
`;

const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledUserData = styled.div`
  display: grid;
  grid-template:  "form form" calc(50% - 10px)
                  "dandelions wine" calc(50% - 10px) / 1fr 1fr;
  overflow-y: auto;
  gap: 20px;
  margin-top: 2rem;
`;

const StyledUserFormData = styled.div`
  display: grid;
  grid-template:  "name development" calc(33% - 8px)
                  "situation development" calc(33% - 8px)
                  "resources chip" calc(33% - 8px) / 3fr 2fr;
  gap: 12px;
  grid-area: form;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
`;

const StyledUserFeelingTable = styled.table`
  grid-area: dandelions;
  border-spacing: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  td, th {
    text-align: left;
    border: 1px solid #a3a3a388;
    padding: 1rem;
  }

  td {
    border-top: none;

    &:not(:first-child) {
      border-left: none;
    }
  }

  th {
    &:first-child {
      border-radius: 2px 0 0 0;
    }

    &:last-child {
      border-radius: 0 2px 0 0;
    }

    &:not(:first-child) {
      border-left: none;
    }
  }

  tr:last-child {
    td:first-child {
      border-radius: 0 0 0 2px;
    }

    td:last-child {
      border-radius: 0 0 2px 0;
    }
  }
`;

const StyledTableInput = styled.input`
  border: none;
  background: none;
  padding: 1rem;
  margin: -1rem;
`;

const StyledChip = styled(Chip)`
/* Minus line height of the label */
  max-height: 35px;
  path {
    fill: ${(props: { color: string }) => props.color || 'white'};
  }
`;

const StyledTextArea = styled.textarea`
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  resize: none;
  padding: 1rem;
`;

const StyledCloseBtn = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  background: url(${iconClose}) left center no-repeat;
  padding: 0.5rem;
  padding-left: 1.5rem;
  border: none;
  font-size: 14px;
`;

const ModalPlayerInfo = observer((props: { playerKey?: string, close(): void }) => {
  const { roomKey } = useParams<RouteParams>();
  const [activePlayerKey, setActivePlayerKey] = useState(props.playerKey);
  const [playerData, setPlayerData] = useState(null as PlayerData | null);
  const [refreshFlag, setRefreshFlag] = useState(false);

  React.useEffect(() => {
    if (activePlayerKey === null) return;
    setPlayerData(null);

    api.get(`game_sessions/${roomKey}/players/${activePlayerKey}`).then(res => {
      if (res.data.form_data === null) {
        res.data.form_data = { lost_resources: ['', '', ''] };
      }
      setPlayerData(res.data);
      setRefreshFlag(true);
    });
  }, [activePlayerKey, roomKey]);

  React.useEffect(() => {
    if (!refreshFlag) return;

    autorun(() => {
      const player = {...[...store.players, store.currentPlayer].find(player => player?.session_key === activePlayerKey)} as Player;
      toJS(player?.emotion_table_filled);
      if (player?.form_data === null) {
        player.form_data = { name: '', situation: '', situation_result: '', lost_resources: ['', '', ''] };
      } else {
        player.form_data = {
          ...playerData?.form_data,
          ...player.form_data
        };
      }
      player && (player.emotion_table_filled = {
        ...playerData?.emotion_table_filled,
        ...player.emotion_table_filled
      });

      if (player && playerData) {
        setPlayerData(data => ({...data, ...player}));
      } 
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFlag]);

  const setPlayerField = (field: string, value: any) => {
    if (!playerData) return;

    setPlayerData({...playerData, [field]: value});
  }

  const saveFormChanges = () => {
    wsApi.sendEvent(EventPlayerChangeRequestForm.type, {
      ...playerData?.form_data,
      player_key: activePlayerKey
    });
  }

  const saveFeelingChanges = () => {
    wsApi.sendEvent(EventPlayerChangeEmotionTable.type, {
      emotion_table: playerData!.emotion_table_filled,
      player_key: playerData!.session_key
    });
  }

  return (
    <CenteredSection>
      <StyledCloseBtn onClick={props.close}>Закрыть</StyledCloseBtn>
      <StyledTabList>
        { [...store.players, store.currentPlayer].map(player => player && (
          <StyledTab key={player.session_key} className={activePlayerKey === player.session_key ? 'active' : ''} onClick={() => setActivePlayerKey(player.session_key)}>{player.name}</StyledTab>
        )) }
      </StyledTabList>
      <div style={{overflowY: 'auto'}}> {/* removing this breaks the layout because of grid kinks */}
        { playerData && 
          <StyledUserData>
            <StyledUserFormData>
              <div style={{gridArea: 'name'}}>
                <StyledLabel>Меня зовут</StyledLabel>
                <Input value={playerData.form_data.name} onChange={e => setPlayerField('form_data', {...playerData.form_data, name: e.currentTarget.value})} onBlur={saveFormChanges} />
              </div>
              <div style={{gridArea: 'situation'}}>
                <StyledLabel>Ситуация, которая доставляет мне беспокойство</StyledLabel>
                <Input value={playerData.form_data.situation} onChange={e => setPlayerField('form_data', {...playerData.form_data, situation: e.currentTarget.value})} onBlur={saveFormChanges} />
              </div>
              <div style={{gridArea: 'resources'}}>
                <StyledLabel>Ресурсы, которые я теряю</StyledLabel>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Input style={{width: '30%'}} value={playerData.form_data.lost_resources[0]} onChange={e => setPlayerField('form_data', {...playerData.form_data, lost_resources: [e.currentTarget.value, playerData.form_data.lost_resources[1], playerData.form_data.lost_resources[2]]})} onBlur={saveFormChanges} />
                  <Input style={{width: '30%'}} value={playerData.form_data.lost_resources[1]} onChange={e => setPlayerField('form_data', {...playerData.form_data, lost_resources: [playerData.form_data.lost_resources[0], e.currentTarget.value, playerData.form_data.lost_resources[2]]})} onBlur={saveFormChanges} />
                  <Input style={{width: '30%'}} value={playerData.form_data.lost_resources[2]} onChange={e => setPlayerField('form_data', {...playerData.form_data, lost_resources: [playerData.form_data.lost_resources[0], playerData.form_data.lost_resources[1], e.currentTarget.value]})} onBlur={saveFormChanges} />
                </div>
              </div>
              <div style={{gridArea: 'development'}}>
                <StyledLabel>Развитие этой ситуации</StyledLabel>
                <Input value={playerData?.form_data.situation_result} onChange={e => setPlayerField('form_data', {...playerData.form_data, situation_result: e.currentTarget.value})} onBlur={saveFormChanges} />
              </div>
              <div style={{gridArea: 'chip'}}>
                <StyledLabel>Выбранная бабочка</StyledLabel>
                <StyledChip color={playerData.chip?.color || 'gray'} />
              </div>
            </StyledUserFormData>
            <StyledUserFeelingTable>
              <thead>
                <tr>
                  <th>Чувство</th>
                  <th>Мои "якоря"</th>
                </tr>
              </thead>
              <tbody>
                { Object.keys(playerData.emotion_table_filled).map(key => (
                  <tr key={key}>
                    <td style={{color: feelingColors[key]}}>{key}</td>
                    <td><StyledTableInput value={playerData.emotion_table_filled[key]} onChange={e => setPlayerField('emotion_table_filled', {...playerData.emotion_table_filled, [key]: e.currentTarget.value})} onBlur={saveFeelingChanges} style={{width: '100%'}} /></td>
                  </tr>
                )) }
              </tbody>
            </StyledUserFeelingTable>
            <StyledTextArea style={{gridArea: 'wine'}} value={playerData.juice_text || ''} onChange={e => setPlayerField('juice_text', e.currentTarget.value)} onBlur={e => wsApi.sendEvent(EventPlayerChangeJuiceText.type, { juice_text: e.currentTarget.value, player_key: playerData.session_key })} />
          </StyledUserData> 
        }
      </div>
    </CenteredSection>
  )
});

export default ModalPlayerInfo;
