import React, { useState, useEffect } from 'react';
import CenteredSection from '../base/CenteredSection';
import Block from '../base/Block';
import styled from 'styled-components';
import chip1 from '../../assets/chip-1.svg';
import chip2 from '../../assets/chip-2.svg';
import chip3 from '../../assets/chip-3.svg';
import chip4 from '../../assets/chip-4.svg';
import chip5 from '../../assets/chip-5.svg';
import chip6 from '../../assets/chip-6.svg';
import Button from '../base/Button';
import wsApi from '../../api/wsApi';
import { EventPlayerSelectChip } from '../../api/wsEvents';
import { observer } from 'mobx-react';
import store from '../../store/roomStore';
import { autorun } from 'mobx';
import game from '../../game/main';
import api from '../../api/api';
import { useParams } from 'react-router-dom';

interface Props {
	onFinish(): any
}

const CenteredBlock = styled(Block)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 3rem auto;
  padding: 2rem;

  .chip-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 8rem;
    width: calc(100% / 7);
    text-align: center;
    height: 8rem;
    cursor: pointer;

    &.active {
      border: 1px solid #A0D95A;
      border-radius: 10px;
    }
  }

  .chip-img {
    width: 60%;
    height: 60%;
    pointer-events: none;
  }
`;

const deactivateObject = (object: HTMLObjectElement) => {
  const path = object.getSVGDocument()?.getElementsByTagName('path')[0];
  path?.setAttribute('opacity', '0.25');
  object?.parentElement?.style.setProperty('pointer-events', 'none');
}

const activateObject = (object: HTMLObjectElement) => {
  const path = object.getSVGDocument()?.getElementsByTagName('path')[0];
  path?.setAttribute('opacity', '1');
  object?.parentElement?.style.setProperty('pointer-events', 'auto');
}

export default observer((props: Props) => {
  const [activeChip, setActiveChip] = useState(null as number | null);
  const {roomKey} = useParams();

  const setChip = (index: number) => {
    // Get SVG's fill color as string
    const color = (document.getElementById(`chip-${index}`)! as HTMLObjectElement).getSVGDocument()!.getElementsByTagName('path')[0].getAttribute('fill')!;
    setActiveChip(index);
    if (store.currentPlayer?.chip?.color !== color) {
      store.currentPlayer!.chip = {...store.currentPlayer!.chip, color};
      wsApi.sendEvent(EventPlayerSelectChip.type, { chip: { color } }, true);
    }
  }

  useEffect(() => {
    const activateChip = (object: HTMLObjectElement) => {
      const color = object.getSVGDocument()?.getElementsByTagName('path')[0]?.getAttribute('fill')!;
      if (store.selectedChips.includes(color) && store.currentPlayer!.chip?.color !== color) {
        deactivateObject(object);
      } else {
        activateObject(object);
        if (store.selectedChips.includes(color)) {
          setChip(parseInt(object.id.split('-')[1]));
        }
      }
    }
    const activateChips = () => {
      Array.from(document.getElementById('choose-chip-modal')?.getElementsByTagName('object') || []).forEach(object => {
        object.onload = () => activateChip(object);
        activateChip(object);
      });
    };
    if (!store.loaded) {
      store.fetchRoom(roomKey!).then(() => {
        game.useWebSocket(api.defaults.baseURL!.replace('http', 'ws') + `game-stream/${roomKey}`).then(() => {
          game.dispose();
          game.init();
          activateChips();
          autorun(activateChips);
        });
      });
    } else {
      activateChips();
      autorun(activateChips);
    }
  }, [roomKey]);

  return (
    <CenteredSection id="choose-chip-modal">
      <h1>Выберите фишку</h1>
      <h2 className="fw-normal text-center">Для старта игры вам необходимо выбрать бабочку любого цвета.<br/>После этого вы можете начать игру</h2>
      <CenteredBlock>
        {
          [chip1, chip2, chip3, chip4, chip5, chip6].map((chip, index) => (
            <div className={`chip-wrapper ${index === activeChip ? 'active' : ''}`} key={index} onClick={() => setChip(index)} >
              <object type="image/svg+xml" className="chip-img" data={chip} title={`Фишка #${index}`} id={`chip-${index}`} />
            </div>
          ))
        }
      </CenteredBlock>
			<Button onClick={props.onFinish} disabled={activeChip === null}>Начать игру</Button>
    </CenteredSection>
  )
})
