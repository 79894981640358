let socket: WebSocket;

function init(path: string, onopen: (ev: Event) => void, onmessage: (ev: MessageEvent) => void) {
  socket = new WebSocket(path);
  socket.onopen = onopen;
  socket.onmessage = onmessage;
  socket.onclose = function(this: WebSocket, ev: Event) {
    console.log(ev)
  }
  socket.onerror = function(this: WebSocket, ev: Event) {
    console.error(ev);
    alert('Произошла ошибка; Соединение разорвано. Пожалуйста, перезагрузите страницу');
  }
}

function sendEvent(event_type: string, data: any, sendBack?: boolean) {
  if (socket) {
    console.log(`Sending ${event_type}:`, data);
    socket.send(JSON.stringify({ event_type, data, rq: !!sendBack }));
  } else {
    console.log(`Not sent: ${event_type}:`, data);
  }
}

export default {
  init, sendEvent
}