import React from 'react';
import styled from 'styled-components';
import iconNext from '../assets/icon-next.svg';
import iconPause from '../assets/icon-pause.svg';
import iconPlay from '../assets/icon-play.svg';
import store, { RoomStatus } from '../store/roomStore';
import MiniCard from './base/MiniCard';
import wsApi from '../api/wsApi';
import { EventGamePaused, EventGameStarted, EventGamePhase1Finished } from '../api/wsEvents';
import Card from './base/Card';

interface Props {
  style: React.CSSProperties
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledCard = styled(MiniCard)`
  &:active {
    background-color: rgba(0, 0, 0, 0.0125);
  }
`;

const startGame = () => {
  if (store.roomStatus === RoomStatus.Paused || store.roomStatus === RoomStatus.Waiting) {
    wsApi.sendEvent(EventGameStarted.type, "", true);
  }
}

const pauseGame = () => {
  if (store.roomStatus === RoomStatus.Started) {
    wsApi.sendEvent(EventGamePaused.type, "", true);
  }
}

const finishGame = () => {
  wsApi.sendEvent(EventGamePhase1Finished.type, null, true);
}

export default function GameControls(props: Props) {
  return (
    <FlexContainer style={{...props.style, width: 'max-content'}}>
      <Card>
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: '3rem'}}>
          <div className="pb-2" style={{flexBasis: '100%'}}>
            <span className="pr-2">Название: </span>
            <b className="pr-5">{store.roomName} </b>
          </div>
          <span className="pr-2">Кол-во игроков: </span>
          <b className="pr-5">{store.roomMaxPlayers} </b>
          <span className="pr-2">Время игры: </span>
          <b>{store.roomTimes} </b>
        </div>
      </Card>
      <div style={{flexBasis: '100%'}}></div>
      <StyledCard className="pointer flex align-center mt-2 mr-2" onClick={startGame}>
        <img className="pr-3" src={iconPlay} alt="Играть" />
        <span>Играть</span>
      </StyledCard>
      <StyledCard className="pointer flex align-center mt-2 mr-2" onClick={pauseGame}>
        <img className="pr-3" src={iconPause} alt="Пауза" />
        <span>Пауза</span>
      </StyledCard>
      <StyledCard className="pointer flex align-center mt-2 mr-auto" onClick={finishGame}>
        <img className="pr-3" src={iconNext} alt="Завершить игру"/>
        <span>Завершить игру</span>
      </StyledCard>
    </FlexContainer>
  )
}
