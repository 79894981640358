import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode,
  label?: string,
  labelWidth: string
}

const StyledItem = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;

  > .label {
    flex-basis: ${(props: Props) => props.labelWidth};
    line-height: 34px;
    text-align: right;
  }

  > .input {
    flex-basis: ${(props: Props) => `calc(100% - ${props.labelWidth} - 20px)`};
    
    > * {
      margin-right: auto;
      border-radius: 3px;
      font-size: 1rem;
    }

    > textarea {
      border: 1px solid #d1d1d1;
      width: 100%;
      padding: 0.5em 1em;
      resize: none;
    }
  }
`;

export default function FormItem(props: Props) {
  return (
    <StyledItem labelWidth={props.labelWidth}>
      <span className="label">{props.label}</span>
      <div className="input">{props.children}</div>
    </StyledItem>
  )
}
