import React from 'react';
import Card from './base/Card';
import styled from 'styled-components';
import iconQuote from '../assets/icon-quote.svg';
import { Player } from '../store/roomStore';

interface Props {
  quote: string,
  sender: Player
}

const StyledCard = styled(Card)`
  max-width: 16vw;
  min-width: 16vw;
  height: 180px;
  display: flex;
  flex-direction: column;
`;

const StyledReceival = styled.h4`
  font-weight: bold;
  font-size: 14px;
  color: #A0D95A;
  margin-bottom: 10px;
`;

const StyledQuote = styled.p`
  font-size: 16px;
  overflow-y: auto;
  flex-grow: 1;
  
  &::before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 11px;
    background-image: url(${iconQuote});
    margin-right: 7px;
  }
`;

const StyledAuthor = styled.div`
  font-size: 12px;
  text-align: right;
`;

export default function QuoteCard(props: Props) {
  return (
    <StyledCard>
      <StyledReceival>от {props.sender.name}</StyledReceival>
      <StyledQuote>{props.quote}</StyledQuote>
      <StyledAuthor>—  Рэй Брэдбери</StyledAuthor>
    </StyledCard>
  )
}
