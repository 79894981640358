import React, { useEffect, useState } from 'react';
import PlayerItem from '../components/PlayerItem';
import styled from 'styled-components';
import game from '../game/main';
import { useParams, Redirect } from 'react-router-dom';
import api from '../api/api';
import { observer } from 'mobx-react';
import roomStore, { PlayerTurnPhases, RoomStatus, ViewPhase } from '../store/roomStore';
import CurrentPlayer from '../components/CurrentPlayer';
import Card from '../components/base/Card';
import Button from '../components/base/Button';
import store from '../store/roomStore';
import wsApi from '../api/wsApi';
import { EventPuzzleStackFlipNext } from '../api/wsEvents';
import ModalRegister from '../components/modals/ModalRegister';
import ModalChooseChip from '../components/modals/ModalChooseChip';
import GameControls from '../components/GameControls';
import MiniCard from '../components/base/MiniCard';
import pauseIcon from '../assets/icon-pause.svg';
import { autorun } from 'mobx';
import ModalOpenFeeling from '../components/modals/ModalOpenFeeling';
import Deck from '../components/Deck';
import ModalDandelionsTable from '../components/modals/ModalDandelionsTable';
import ModalChooseQuote from '../components/modals/ModalChooseQuote';
import QuoteCard from '../components/QuoteCard';
import ModalPlayerInfo from '../components/modals/ModalPlayerInfo';
import { useQuery } from '../game/utils';
import ModalEditingJuice from '../components/modals/ModalEditingJuice';
import ModalMantra from '../components/modals/ModalMantra';

interface RouteParams {
  roomKey: string
}

const Wrapper = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: auto;

  > * {
    position: absolute;
  }
`;

const ModalWrapper = styled.div`
	position: fixed;
	background-color: #fff;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
  align-items: center;
  z-index: 200;

	> * {
		width: 100%;
	}
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuoteCards = styled.div`
  position: absolute;
  left: 32px;
  bottom: 20px;
`;

const QuoteCardList = styled.div`
  display: flex;
  max-width: calc(100vw - 64px);
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  transform-origin: bottom left;
  transition: all 0.2s ease-in-out;

  &:hover {
    overflow-x: auto;
  }

  &.hidden {
    transform: scale(0.5, 0);
    opacity: 0;
  }

  > * + * {
    margin-left: 32px;
  }
`;

const Game = observer(() => {
  const [canvasWidth, setCanvasWidth] = useState(1000);
  const [canvasHeight, setCanvasHeight] = useState(500);
  const { roomKey } = useParams<RouteParams>();
  const query = useQuery();
  const [modal, setModal] = useState(null as React.ReactNode);
  const [redirect, setRedirect] = useState('');
  const [quotesVisible, setQuotesVisible] = useState(true);

  const displayPlayerInfo = (playerKey: string) => {
    setModal(<ModalPlayerInfo playerKey={playerKey} close={() => setModal(null)} />);
  }

  const loadGameData = () => {
    const phasesToLoad = [ViewPhase.Play, ViewPhase.ViewingDandelionTable, ViewPhase.ChoosingQuote];
    if (store.loaded || store.loading || !phasesToLoad.includes(store.viewPhase)) return;

    const keySecured = new Promise(async resolve => {
      await store.fetchRoom(roomKey);

      if (!store.isAdmin && query.get('spectate') !== null) {
        alert('Вы должны быть администратором, чтобы наблюдать за игрой!');
        setRedirect('/');
      }

      if (query.get('spectate') !== null && !store.spectators.find(spectator => spectator.session_key === localStorage.getItem('session_key'))) {
        api.post(`rooms/${roomKey}/members`, {}, { params: { view_only: true } }).then(res => {
          localStorage.setItem('session_key', res.data.session_key);
          localStorage.setItem('room_key', roomKey);
          resolve();
        });
      } else {
        resolve();
      }
    });
    
    keySecured.then(() => {
      game.useWebSocket(api.defaults.baseURL!.replace('http', 'ws') + `game-stream/${roomKey}`).then(() => {
        game.dispose();
        game.init();
      });
    });
  }

  const managePhase = () => {

    if (query.get("spectate") !== null) {
      store.viewPhase = ViewPhase.Play;
      return;
    }

    if (store.viewPhase !== ViewPhase.Register && !!roomKey && (!localStorage.getItem('session_key') || localStorage.getItem('room_key') !== roomKey)) {
      store.viewPhase = ViewPhase.Register;
      return;
    }
  
		switch (store.viewPhase) {
			case ViewPhase.Register:
				setModal(<ModalRegister onFinish={() => store.viewPhase = ViewPhase.ChooseChip} />);
				return;
			case ViewPhase.ChooseChip:
				setModal(<ModalChooseChip onFinish={() => store.viewPhase = ViewPhase.Play} />);
        return;
      case ViewPhase.OpeningFeeling:
        setModal(<ModalOpenFeeling onFinish={() => {store.viewPhase = ViewPhase.ViewingDandelionTable; loadGameData()}} />);
        break;
      case ViewPhase.ViewingDandelionTable:
        setModal(<ModalDandelionsTable onFinish={() => store.viewPhase = ViewPhase.Play} />);
        break;
      case ViewPhase.ChoosingQuote:
        setModal(<ModalChooseQuote onFinish={() => {
          if (!store.placedChips[store.currentPlayer!.session_key]) {
            store.viewPhase = ViewPhase.OpeningFeeling
          } else {
            store.viewPhase = ViewPhase.Play;
          }
        }} />);
        break;
      case ViewPhase.EditingJuice:
        setModal(<ModalEditingJuice onFinish={() => store.viewPhase = ViewPhase.EditingMantra} />);
        break;
      case ViewPhase.EditingMantra:
        setModal(<ModalMantra />);
        break;
			default: // ViewPhase.Play 
        setModal(null);
    }
  }
  
  useEffect(() => {
    const debounce = (func: any, ms: number) => {
      let timeout: number;

      return () => {
        timeout && clearTimeout(timeout);
        timeout = window.setTimeout(func, ms);
      }
    }

    const reinit = debounce(() => {
      setCanvasWidth(window.innerWidth);
      setCanvasHeight(window.innerHeight);

      game.dispose();
      game.init();
    }, 100);

    setCanvasWidth(window.innerWidth);
    setCanvasHeight(window.innerHeight);

    window.addEventListener('resize', reinit);
  }, []);

  useEffect(() => {
    autorun(() => {
      if (store.isKicked) {
        alert('Вас исключили из игры!');
        setRedirect('/');
        store.isKicked = false;
        return;
      }
    });
  }, []);

  useEffect(() => {
    autorun(managePhase);
    managePhase();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomKey]);

  useEffect(loadGameData, [roomKey]);

  return redirect ? <Redirect to={redirect} /> : ( 
    <>
      <Wrapper onContextMenu={e => e.preventDefault()} style={{display: store.loading ? 'none' : 'block', pointerEvents: store.roomStatus === RoomStatus.Paused ? 'none' : 'all'}}>
        <canvas id="canvas-main" data-width={canvasWidth} data-height={canvasHeight}></canvas>
        { !!roomStore.activePlayers.length && 
            <Card style={{top: '30px', left: '36px', maxHeight: 'calc(100vh - 240px)'}}>
              { roomStore.activePlayers.map((player) => (
                <PlayerItem key={JSON.stringify(player)} player={player} displayInfo={() => displayPlayerInfo(player.session_key)} />
              )) }
            </Card> 
        }
        <FlexContainer style={{top: '30px', right: '30px', zIndex: 101}}>
          {store.isAdmin && <GameControls style={{marginRight: '1rem', pointerEvents: 'all', maxWidth: '30rem'}} />}
          <div style={{position: 'relative'}}>
            {!!store.currentPlayer && <CurrentPlayer />}
            <div style={{position: 'absolute', top: '100%', right: '0'}}>
              <Deck />
              <div style={{textAlign: 'right', marginTop: '15px'}}>
                {
                  (store.playerTurnPhase === PlayerTurnPhases.TurnStart || store.playerTurnPhase === PlayerTurnPhases.PlacingExtraChip) && <Button onClick={() => {wsApi.sendEvent(EventPuzzleStackFlipNext.type, {}, true); store.isTopPuzzleFlipped = true; store.playerTurnPhase = PlayerTurnPhases.PlacingPuzzle }}>Собираем одуванчики!</Button>
                }
                { 
                  store.playerTurnPhase === PlayerTurnPhases.PlacedPuzzle && <Button onClick={() => {store.endTurn()}}>Бабочка в полёте!</Button>
                }
              </div>
            </div>
          </div>
        </FlexContainer>
        { store.roomStatus === RoomStatus.Paused && (
          <StyledModal>
            <MiniCard className="ml-3 pa-5"><img src={pauseIcon} alt="Игра приостановлена" title="Игра приостановлена" /></MiniCard> 
          </StyledModal>
        ) }
				{ modal && 
          <ModalWrapper>
            { modal }
          </ModalWrapper> 
        }
        <QuoteCards>
          <QuoteCardList className={!quotesVisible ? 'hidden' : ''}>
          {
            store.quotes.map(quote => (
              <QuoteCard key={quote.text} quote={quote.text} sender={quote.sender} />
            ))
          }
          </QuoteCardList>
          { store.quotes.length > 0 && 
            <Button onClick={() => setQuotesVisible(!quotesVisible)}>{quotesVisible ? 'Спрятать цитаты' : 'Показать цитаты'}</Button> 
          }
        </QuoteCards>
      </Wrapper>
      <h1 style={{display: store.loading ? 'block' : 'none'}}>Комната загружается...</h1> 
    </>
	)
});

export default Game;