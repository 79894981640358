import React from 'react';
import CenteredSection from '../components/base/CenteredSection';
import FormItem from '../components/FormItem';
import Input from '../components/base/Input';
import styled from 'styled-components';
import Button from '../components/base/Button';
import api from '../api/api';
import { Redirect } from 'react-router-dom';

const StyledForm = styled.form`
  width: 50%;
  margin: 20px auto;
`;

const stringToDate = (str: string) => {
  const s = str.split(/[. :]/);

  return new Date(Date.parse(`${s[2]}-${s[1]}-${s[0]}T${s[3]}:${s[4]}`));
}

export default function CreateSession() {
  const [formData, setFormData] = React.useState({
    name: '',
    playersMax: '',
    startTime: '',
    endTime: ''
  });
  const [redirect, setRedirect] = React.useState(false)

  const setFormItem = (field: string, value: string) => {
    setFormData({...formData, [field]: value});
  }

  const createSession = () => {
    const dateEx = /^\d\d\.\d\d\.\d\d\d\d \d\d:\d\d$/;
    if (!dateEx.test(formData.startTime) || !dateEx.test(formData.endTime)) {
      alert('Убедитесь, что обе даты указаны в формате "ДД.ММ.ГГГГ ЧЧ:мм"');
      return;
    }

    if (parseInt(formData.playersMax) < 2 || parseInt(formData.playersMax) > 6) {
      alert('Введите корректное количество возможных игроков (2-6)');
      return;
    }

    api.post('rooms', {
      title: formData.name,
      start_dt: stringToDate(formData.startTime).toISOString(),
      end_dt: stringToDate(formData.startTime).toISOString(),
      max_members_count: formData.playersMax
    }).then(res => {
      setRedirect(true);
    }).catch(e => {
      console.error(e);
      alert('Не удалось создать комнату. Подробности выведены в консоль.');
    })
  }

  return redirect ? <Redirect to="/list" /> : (
    <CenteredSection>
      <h1>Создание игровой сессии</h1>
      <StyledForm>
        <FormItem labelWidth="200px" label="Название">
          <Input placeholder="Название" value={formData.name} onChange={e => setFormItem('name', e.currentTarget.value)} />
        </FormItem>
        <FormItem labelWidth="200px" label="Количество игроков">
          <Input placeholder="6" value={formData.playersMax} onChange={e => setFormItem('playersMax', e.currentTarget.value)} />
        </FormItem>
        <FormItem labelWidth="200px" label="Время начала">
          <Input placeholder="11.11.2011 11:11" value={formData.startTime} onChange={e => setFormItem('startTime', e.currentTarget.value)} />
        </FormItem>
        <FormItem labelWidth="200px" label="Время окончания">
          <Input placeholder="11.11.2011 11:11" value={formData.endTime} onChange={e => setFormItem('endTime', e.currentTarget.value)} />
        </FormItem>
      </StyledForm>
      <Button onClick={createSession}>Создать</Button>
    </CenteredSection>
  )
}
