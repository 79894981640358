import React, { useState, useEffect } from 'react';
import CenteredSection from '../components/base/CenteredSection';
import styled from 'styled-components';
import api from '../api/api';
import { Link, Redirect } from 'react-router-dom';
import Button from '../components/base/Button';
import store from '../store/roomStore';

const StyledTable = styled.table`
  border-collapse: collapse;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin: 1rem;

  &, td, th {
    border: 1px solid #0002;
    padding: 1rem;
  }

  thead tr, tr:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }

  .btn {
    cursor: pointer;
    padding: 5px;
    margin: 2px;
    /* color: var(--icon-color);
    border-color: var(--icon-color); */
  }
`;

const StyledLink = styled(Link)`
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: auto;
`;

const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledStatus = styled.div`
  padding: 5px 10px;
  color: white;
  border-radius: 4px;
  text-align: center;
  text-transform: lowercase;
`;

function copyText(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed'; // avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

enum GameStatus {
  Waiting = 0, Started = 1, Paused = 2, Finished = 3, Closed = 5
}

const GameStatusColors = {
  [GameStatus.Waiting]: '#FFCD00',
  [GameStatus.Started]: '#A0D95A',
  [GameStatus.Paused]: '#EC6E28',
  [GameStatus.Finished]: '#9B8ADE',
  [GameStatus.Closed]: '#F53F5F'
}

const GameStatusNames = {
  [GameStatus.Waiting]: 'Ожидается',
  [GameStatus.Started]: 'Игра идёт',
  [GameStatus.Paused]: 'Приостановлена',
  [GameStatus.Finished]: 'Завершается',
  [GameStatus.Closed]: 'Проведена'
}

export default function GameList() {
  const [sessions, setSessions] = useState([] as any[]);
  const [redirect, setRedirect] = useState(null as JSX.Element | null);

  useEffect(() => {
    api.get('rooms').then(res => {
      if (!res || !localStorage.getItem('token')) {
        setRedirect(<Redirect to="/" />);
        store.isAdmin = false;
        return;
      }
      setSessions(res.data);
    });
  }, []);

  const tableRows = sessions.map(session => <tr key={session.id}>
    <td>{session.id}</td>
    <td>{session.title}</td>
    <td>{session.max_members_count}</td>
    <td>{session.start_dt}</td>
    <td>{session.end_dt}</td>
    <td><StyledStatus style={{backgroundColor: GameStatusColors[session.status as GameStatus]}}>{GameStatusNames[session.status as GameStatus]}</StyledStatus></td>
    <td>
      <StyledLinkWrapper>
        <StyledLink to={`/${session.session_key}`}>{session.session_key}</StyledLink>
        <span 
          className="btn" 
          style={{color: '#1EA8DD'}}
          title="Копировать ссылку"
          role="img" 
          aria-label="copy" 
          onClick={() => copyText(`${window.location.origin}/${session.session_key}`)}
        >⎘</span>
        <Link to={`/${session.session_key}`}>
          <span 
            className="btn"
            style={{color: '#A0D95A'}}
            title="Участвовать в игре"
            role="img"
            aria-label="play"
          >▶</span>
        </Link>
        <Link to={`/${session.session_key}?spectate`}>
          <span 
            className="btn"
            style={{color: '#FFCD00'}}
            title="Войти как наблюдатель"
            role="img"
            aria-label="spectate"
          >◉</span>
        </Link>
      </StyledLinkWrapper>
    </td>
  </tr>)

  return redirect || (
    <CenteredSection>
      <h1>Список сессий</h1>
      <StyledTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название игры</th>
            <th>Кол-во игроков</th>
            <th>Время начала</th>
            <th>Время окончания</th>
            <th>Статус</th>
            <th>Подключение</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </StyledTable>
      <Link to="/list/create">
        <Button>Создать новую сессию</Button>
      </Link>
    </CenteredSection>
  )
}
