import React, { useEffect, useState } from 'react';
import CenteredSection from '../base/CenteredSection';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import store from '../../store/roomStore';
import game from '../../game/main';
import api from '../../api/api';
import wsApi from '../../api/wsApi';
import { EventPlayerChangeEmotionTable } from '../../api/wsEvents';
import { autorun } from 'mobx';

interface RouteParams {
  roomKey: string
}

interface Feelings {
  [name: string]: string
}

interface Props {
  onFinish(): void
}

const StyledTable = styled.table`
  border-spacing: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  td, th {
    text-align: left;
    border: 1px solid #a3a3a388;
    padding: 1rem;
  }

  td {
    border-top: none;

    &:not(:first-child) {
      border-left: none;
    }
  }

  th {
    &:first-child {
      border-radius: 2px 0 0 0;
    }

    &:last-child {
      border-radius: 0 2px 0 0;
    }

    &:not(:first-child) {
      border-left: none;
    }
  }

  tr:last-child {
    td:first-child {
      border-radius: 0 0 0 2px;
    }

    td:last-child {
      border-radius: 0 0 2px 0;
    }
  }
`;

const StyledInput = styled.input`
  border: none;
  background: none;
  padding: 1rem;
  width: 30rem;
`;

const StyledButton = styled.button`
  cursor: pointer;
  color: #85C03C;
  border: 1px solid #85C03C;
  font-size: 14px;
  background: #fff;
  box-shadow: 0px 4px 5px rgba(16, 156, 241, 0.1);
  border-radius: 4px;
  padding: 13px 30px;
  margin-top: 48px;
`;

export const feelingColors: Feelings = {
  'Образ': '#1EA8DD',
  'Вкус': '#EC6E28',
  'Аромат': '#222222',
  'Ощущение': '#9B8ADE',
  'Звук': '#F53F5F'
}

export default function ModalDandelionsTable(props: Props) {
  const { roomKey } = useParams<RouteParams>();
  const [feelings, setFeelings] = useState<Feelings>({});

  const setFeeling = (feeling: string, value: string) => {
    setFeelings({...feelings, [feeling]: value});
  }

  const loadGameData = () => {
    const loaded = new Promise(resolve => {
      if (!store.loaded && !store.loaded) {
        Promise.all([
          game.useWebSocket(api.defaults.baseURL!.replace('http', 'ws') + `game-stream/${roomKey}`),
          store.fetchRoom(roomKey)
        ]).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });

    loaded.then(() => {
      if (!store.currentPlayer) return;

      api.get(`game_sessions/${roomKey}/players/${store.currentPlayer.session_key}`).then(res => {
        autorun(() => {
          store.currentPlayer?.emotion_table_filled && setFeelings({...feelings, ...store.currentPlayer.emotion_table_filled});
        });

        setFeelings({...feelings, ...res.data['emotion_table_filled']});
        autofocus();
      });
    });
  }

  const saveFeeling = (feeling: string, value: string) => {
    wsApi.sendEvent(EventPlayerChangeEmotionTable.type, {
      emotion_table: {
        [feeling]: value
      },
      player_key: store.currentPlayer?.session_key
    });
  }

  useEffect(loadGameData, [roomKey]);

  const autofocus = () => {
    console.log(document.querySelector(`[data-feeling="${localStorage.getItem('last-emotion')}"]`));
    (document.querySelector(`[data-feeling="${localStorage.getItem('last-emotion')}"]`) as HTMLElement)?.focus();
  }
  
  return (
    <CenteredSection>
      <h1>Мои одуванчики</h1>
      <StyledTable className="mt-4">
        <thead>
          <tr>
            <th>№</th>
            <th>Чувство</th>
            <th>Мои "якоря"</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(feelings).map((feeling, index) => (
              <tr key={feeling}>
                <td>{index + 1}</td>
                <td style={{color: feelingColors[feeling] || '#222', fontStyle: localStorage.getItem('last-emotion') === feeling ? 'italic' : 'normal'}}>{feeling}</td>
                <td className="pa-0"><StyledInput value={feelings[feeling]} onChange={e => setFeeling(feeling, e.currentTarget.value)} onBlur={() => saveFeeling(feeling, feelings[feeling])} data-feeling={feeling} /></td>
              </tr>
            ))
          }
        </tbody>
      </StyledTable>
      <StyledButton onClick={props.onFinish}>Сохранить и вернуться в игру</StyledButton>
    </CenteredSection>
  )
}
