import React from 'react';
import Router from './Router';

function App() {
  return (
    <main style={{backgroundColor: '#F9FCF8', minHeight: '100vh'}}>
      <Router />
    </main>
  );
}

export default App;
